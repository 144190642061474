import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import AuthHelper from "../../helpers/AuthHelper";

import {
    Collapse,
    Navbar,
    Nav,
    Container,
    Button,
    ButtonGroup,
} from "reactstrap";

class AdminNavbar extends React.Component {
    redirigir = false;
    // function that on mobile devices makes the search open
    openSearch = () => {
        document.body.classList.add("g-navbar-search-showing");
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-showing");
            document.body.classList.add("g-navbar-search-show");
        }, 150);
        setTimeout(function () {
            document.body.classList.add("g-navbar-search-shown");
        }, 300);
    };
    // function that on mobile devices makes the search close
    closeSearch = () => {
        document.body.classList.remove("g-navbar-search-shown");
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-show");
            document.body.classList.add("g-navbar-search-hiding");
        }, 150);
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-hiding");
            document.body.classList.add("g-navbar-search-hidden");
        }, 300);
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-hidden");
        }, 500);
    };
    afuera = (e) => {
        e.preventDefault()
        AuthHelper.logout()
        this.props.history.push("/login")
    }

    name = AuthHelper.getName()

    render() {

        return (
            <>
                <Navbar
                    className={classnames(
                        "navbar-top navbar-expand border-bottom",
                        { "navbar-dark bg-info": this.props.theme === "dark" },
                        { "navbar-light bg-secondary": this.props.theme === "light" }
                    )}
                >
                    <Container fluid>
                        <Collapse navbar isOpen={true}>
                            <Nav className="align-items-center ml-md-auto" navbar>
                            </Nav>
                            <Nav className="align-items-center ml-auto ml-md-0" navbar>
                                <ButtonGroup>
                                    <Button outline color="default" size="sm" disabled>
                                        <i className="fas fa-user"></i> {this.name}
                                    </Button>
                                    <Button outline color="danger" size="sm" onClick={() => {
                                        AuthHelper.logout();
                                        this.props.history.push('/login')
                                    }}><i className="fas fa-sign-out-alt"></i> Terminar sesión</Button>
                                </ButtonGroup>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }
}

AdminNavbar.defaultProps = {
    toggleSidenav: () => {
    },
    sidenavOpen: false,
    theme: "dark"
};
AdminNavbar.propTypes = {
    toggleSidenav: PropTypes.func,
    sidenavOpen: PropTypes.bool,
    theme: PropTypes.oneOf(["dark", "light"])
};

export default AdminNavbar;
