import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'


const ToothModal = (props) => {
	const {children, isOpen, name, action, toggle} = props
	const {data, setData} = children.props
	// const [isOpen, setIsOpen] = useState(open)
	// const toggle =()=> setIsOpen(!isOpen)

	
	const saveAction =(data)=>{
		//action(data)
		toggle()
	}

	const cancelAction=()=>{
		//action()
		toggle()
	}

	return (
		<>
			<Modal isOpen={isOpen} toggle={toggle}>
				<ModalHeader toggle={toggle}>{name}</ModalHeader>
				<ModalBody>
					{children}
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={() => saveAction(data)}>Aceptar</Button>{' '}
					<Button color="secondary" onClick={cancelAction}>Cancelar</Button>
				</ModalFooter>
			</Modal>
		</>
	)
} 

export default ToothModal