import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Input, Label } from 'reactstrap'

const CoronalCavities = (props) => {
    const { data, setData} = props

    const [coronalCavitie, setCoronalCavitie] = useState(data)

    const surfaces = [
        { id: "oclusal", value: "Incisal/Oclusal" },
        { id: "vestibular", value: "Vestibular" },
        { id: "palatino", value: "Palatino/Lingual" },
        { id: "mesial", value: "Mesial" },
        { id: "distal", value: "Distal" }
    ]
    const clinicalClassifications = [
        { id: 1, value: "ICDAS 1" },
        { id: 2, value: "ICDAS 2" },
        { id: 3, value: "ICDAS 3" },
        { id: 4, value: "ICDAS 4" },
        { id: 5, value: "ICDAS 5" },
        { id: 6, value: "ICDAS 6" }
    ]
    const activities = [
        { id: 1, value: "Activa" },
        { id: 2, value: "Inactiva" }
    ]

    const handleInputChange = (e) => {
        const {id, value} = e.target
        setCoronalCavitie({...coronalCavitie, [id]: value})
    }
    const handleInputCheckbox=(e) =>{
        const {id, checked} = e.target
        setCoronalCavitie({...coronalCavitie, surface: {...coronalCavitie.surface, [id]: checked} })
    }

    useEffect(() => {
        setData(coronalCavitie)
    }, [coronalCavitie])

    
    return (
        <FormGroup className="row m-0">
            <Col md="12">
                <Label className="form-control-label">Ubicación/Superficie</Label>
                <FormGroup className="row">
                    <Col sm={6}>
                        <Col sm={12}>
                            {surfaces.map((item) =>
                                <div key={`surface-${item.id}`} className="custom-control custom-checkbox custom-checkbox-primary">
                                    <Input
                                        className="custom-control-input"
                                        id={item.id}
                                        name={item.value}
                                        type="checkbox"
                                        checked={coronalCavitie.surface[item.id]}
                                        onChange={(e) => {handleInputCheckbox(e)}}
                                        required
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={item.id}
                                    >{item.value}</label>
                                </div>)
                            }
                        </Col>
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Col sm={12}>
                        <Label className="form-control-label">Clasificación Clínica</Label>
                        <Input
                            bsSize="sm"
                            id="classification"
                            name="classification"
                            type="select"
                            value={coronalCavitie.classification}
                            onChange={e => handleInputChange(e)}
                        >
                            <option hidden value="">Seleccionar</option>
                            {clinicalClassifications.map((classification) =>
                                <option
                                    key={classification.id}
                                    value={classification.value}
                                >
                                    {classification.value}
                                </option>
                            )
                            }
                        </Input>
                    </Col>
                </FormGroup>

                <FormGroup className="row">
                    <Col sm={12}>
                        <Label className="form-control-label">Actividad</Label>
                        <Input
                            bsSize="sm"
                            id="activity"
                            name="activity"
                            value={coronalCavitie.activity}
                            type="select"
                            onChange={e => handleInputChange(e)}
                        >
                            <option hidden value="">Seleccionar</option>
                            {activities.map((activity) =>
                                <option
                                    key={activity.id}
                                    value={activity.value}
                                >
                                    {activity.value}
                                </option>
                            )
                            }
                        </Input>
                    </Col>
                </FormGroup>
            </Col>
        </FormGroup>
    )
}

export default CoronalCavities