import React from "react";
import ReactDOM from "react-dom";

import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";

import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";

import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";

import Enroller from "layouts/Enroller";
import AuthLayout from "layouts/Auth.js";
import AuthHelper from "./helpers/AuthHelper";
import Specialist from "layouts/Specialist";
import Dentist from "layouts/Dentist";
import Admin from "layouts/Admin";


const ROLE={
    ADMIN:3,
    ENROLLER:5,
    DENTIST:4,
    SPECIALIST:2
}

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/login" render={props => {
                if (AuthHelper.isAuthenticated()){
                    return <Redirect to="/agenda"/>
                } else {
                    return <AuthLayout {...props} />
                }
            }
        }/>
            <Route path="/" render={props => {
                if (AuthHelper.isAuthenticated()) {
                    if (AuthHelper.getRole()===ROLE.ENROLLER) {return <Enroller {...props}/>}
                    if (AuthHelper.getRole()===ROLE.DENTIST) {return <Dentist {...props}/>}
                    if (AuthHelper.getRole()===ROLE.SPECIALIST) {return <Specialist {...props}/>}
                    if (AuthHelper.getRole()===ROLE.ADMIN) {return <Admin {...props}/>}
                } else {
                    return <Redirect to="/login"/>
                }
            }
            }
            />
            <Redirect from="*" to="/login"/>

        </Switch>
    </BrowserRouter>,
    document.getElementById("root")
);
