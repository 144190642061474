import React from 'react'
import {
    Card,
    CardImg,
    CardTitle,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    Label
} from "reactstrap";


const InjurySelector = ({ modalInjury, toggleInjury, handleInjurySelector }) => {
    

    const handleClickInjury = (e) => {
        handleInjurySelector(e)
        //console.log(e.target.id)
        toggleInjury()
        //injuryId(e.target.id)
    }

    return (

        <Modal size="lg" centered={true} isOpen={modalInjury} toggle={toggleInjury}>
            <ModalHeader toggle={toggleInjury} >Selector de patologías</ModalHeader>
            <Col><Col> {" "}
                    <Label
                                className="form-control-label"
                            >
                                Indique su sospecha diagnóstica 
                            </Label>
                </Col>
            </Col>
            <ModalBody>
                <Container>
                    <Row xs="6">
                        <Col>
                            <Card >
                                <CardImg id="1" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_no_identif.png")} />
                                <CardTitle className="text-center" tag="h5">No identificada</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="2" name="injury" draggable={false} onClick={(e) => handleClickInjury(e) } top width="100" src={require("assets/img/button-injuries/boton_candidiasis.png")} />
                                <CardTitle className="text-center" tag="h5">Candidosis</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="3" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_carcinoma.png")} />
                                <CardTitle className="text-center" tag="h5">Carcinoma</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="4" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_epulis.png")} />
                                <CardTitle className="text-center" tag="h5">Epulis fisurado</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="5" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_eritroplasia.png")} />
                                <CardTitle className="text-center" tag="h5">Eritroplasia</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="6" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_estomatitis.png")} />
                                <CardTitle className="text-center" tag="h5">Estomatitis subprotésica</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="7" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_fibroma.png")} />
                                <CardTitle className="text-center" tag="h5">Fibroma irritativo</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="8" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_granuloma.png")} />
                                <CardTitle className="text-center" tag="h5">Granuloma (GPCG)</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="12" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_hemangioma.png")} />
                                <CardTitle className="text-center" tag="h5">Malformación vascular</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="9" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_hiperplasia.png")} />
                                <CardTitle className="text-center" tag="h5">Hiperplasia papilar</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="10" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_leucoplasia.png")} />
                                <CardTitle className="text-center" tag="h5">Leucoplasia</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="11" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_liquen_plano.png")} />
                                <CardTitle className="text-center" tag="h5">Liquen plano</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="13" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_penfigo-penfigoide.png")} />
                                <CardTitle className="text-center" tag="h5">Penfigoide</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="14" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_penfigo-penfigoide.png")} />
                                <CardTitle className="text-center" tag="h5">Pénfigo</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="15" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_pigmentacion.png")} />
                                <CardTitle className="text-center" tag="h5">Pigmentación melánica</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="17" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_sarcoma.png")} />
                                <CardTitle className="text-center" tag="h5">Sarcoma</CardTitle>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardImg id="16" name="injury" draggable={false} onClick={(e) => handleClickInjury(e)} top width="100" src={require("assets/img/button-injuries/boton_ulcera.png")} />
                                <CardTitle className="text-center" tag="h5">Úlcera traumática</CardTitle>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
            {/*  <ModalFooter>
                        <Button color="primary" onClick={() => console.log('boton')}>Agregar seleccionada</Button>{' '}
                        <Button color="secundary" onClick={toggleInjury}>Cancelar</Button>
                    </ModalFooter> */}
        </Modal>

    )

}
export default InjurySelector