import React, { useState } from "react";
import {
    Collapse,
    CardBody,
    Card,
    CardHeader,
    Badge
} from "reactstrap";

const Accordion = (props) => {
    const {name, children, amount, badge, open} =props

    const [isOpen, setIsOpen] = useState(open);
    const toggle = () => setIsOpen(!isOpen);

    return (

        <Card className="m-0" style={{ boxShadow: "none"}}>
            <CardHeader className="btn text-white bg-primary py-1 mx-0 my-2"  style={{  cursor: "pointer", boxShadow: "none"}}onClick={toggle} >
                {name} {' '}{badge && <Badge color="secondary">{amount}</Badge>}
            </CardHeader>
            <Collapse isOpen={isOpen}>
                    <CardBody className="p-0 m-0" >
                            {children}
                    </CardBody>
            </Collapse>
        </Card>
    )
}

export default Accordion