import React, { useState, useEffect } from 'react'
import { Html } from "@react-three/drei";
import { useThree } from "react-three-fiber";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardImg,
    Row,
    Col,
    CardImgOverlay,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

const TagExtraOral = (props) => {
    const {intersect} = useThree()

    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)

    const [modalDelete, setModalDelete] = useState(false);
    const toggleDelete = () => setModalDelete(!modalDelete);


    const [modalImage, setModalImage] = useState(false);
    const toggleImage = () => setModalImage(!modalImage);


    const handleClick = (e) => {
        if (intersect().length > 0 && intersect()[0].object.name === "marker"){
            e.stopPropagation()
        if(props.adding === 2){
        setActive(!active)
        ImageSrc()
        }}
    }
    
    const [src, setSrc] = useState("")
    const [alt, setAlt] = useState("")

    const ImageSrc=()=>{
        
        if (props.marker.foto === null || props.image.find(image=>image.foto_id === props.marker.foto ) === undefined){
            setSrc(require("assets/img/image-placeholder.png"))} 
        else
        {
            setSrc(process.env.REACT_APP_API_URL + `${props.image.find(image=>image.foto_id === props.marker.foto ).url}` + "/" + `${props.image.find(image=>image.foto_id === props.marker.foto ).foto_nombre}`)
            setAlt(props.image.find(image=>image.foto_id === props.marker.foto ).foto_nombre) 
        }
    }

    return (
        
        <mesh
           {...props}
           scale={active ? [0.06, 0.06, 0.06] : [0.04, 0.04, 0.04]}
           onClick={(e) => handleClick(e)}
           onPointerOver={() => setHover(true)}
           onPointerOut={() => setHover(false)}
           position={[props.marker.position_x, props.marker.position_y, props.marker.position_z]}
           name='marker'
           >
               <sphereBufferGeometry args={[1, 32, 32]} />
               <meshStandardMaterial color={hovered ? 'yellow' : 'lime'} emissive="blue" roughness="0" />
               <Html center={false} style={{transform: 'translate3d(25%,-50%, 0)'}} zIndexRange={[90, 0]}>
                   <Card style={{display: active ? 'block' : 'none', width: "20rem", wordWrap: 'inherit'}}>
                        <Button onClick={() => { setActive(!active) }} className="close" color="primary" size="lg" style={{ zIndex: 100, position: 'absolute', right: "0px", top: "0.4rem" }}><span aria-hidden="true">&times;</span></Button>
                        <ButtonGroup size="lg" style={{ zIndex: 100, position: 'absolute', left: "0px", top: "0px" }}>
                           <Button  color="primary" size="lg" onClick={() => {props.editMarker(props.marker)}}><i className="fas fa-pencil-alt"></i></Button>
                           <Button color="danger" size="sm" onClick={toggleDelete}><i className="far fa-trash-alt"></i></Button>
                       </ButtonGroup>
                       <CardImgOverlay onClick={()=>toggleImage()}></CardImgOverlay>

                       <Modal centered={true} size="xl" isOpen={modalImage} toggle={toggleImage}>
                        <ModalHeader toggle={toggleImage} ></ModalHeader>
                        <ModalBody><img
                            src={src}
                            className="img-fluid"
                            alt={alt}
                        /></ModalBody> 
                        </Modal>
                       <CardImg
                            alt={alt}
                            src={src} 
                            top
                       />   

                       <CardBody style={{padding: "10px"}}>
                                   <div className="small" style={{padding: "5px"}} >
                                   <Row >
                                       <Col sm="5"><h5>Inspección:</h5></Col>
                                            <Col sm="7">{props.marker.inspection}</Col>
                                        <Col sm="5"><h5>Palpación:</h5></Col>
                                            <Col sm="7">{props.marker.palpation}</Col>
                                   </Row>
                                   </div>
                       </CardBody>
                    </Card>
                    <Modal isOpen={modalDelete} toggle={toggleDelete}>
                        <ModalHeader toggle={toggleDelete} >Eliminar</ModalHeader>
                        <ModalBody>¿Seguro que desea eliminar el marcador?</ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => {props.deleteMarker(props.marker.id)}}>Confirmar</Button>{' '}
                            <Button color="secundary" onClick={toggleDelete}>Cancelar</Button>
                        </ModalFooter>
                    </Modal>
               </Html>   
           </mesh >
    )
}
export default TagExtraOral