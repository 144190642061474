import React, { useState, useEffect, useRef } from 'react'
import { Html } from "@react-three/drei";
import { useThree } from "react-three-fiber";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardImg,
    CardTitle,
    CardText,
    CardFooter,
    Row,
    Col,
    CardImgOverlay,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { useGLTF } from '@react-three/drei/useGLTF'
import { getList } from 'helpers/ListHelper';
import TagInfo from './TagInfo';


const Tag = (props) => {

    const {intersect} = useThree()

    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    const { nodes, materials } = useGLTF('/mesh/lesiones.gltf')


    const [modalDelete, setModalDelete] = useState(false);
    const toggleDelete = () => setModalDelete(!modalDelete);

    const [form, setForm] = useState()
    const [color, setColor] = useState()
    const [surface, setSurface] = useState()
    const [consistency, setConsistency] = useState()
    const [evolutionForm, setEvolutionForm] = useState()
    const [symptomatology, setSymptomatology] = useState()
    const [edge, setEdge] = useState()
    const [localization, setLocalization] = useState()
    const [injuries, setInjuries] = useState()


    const position = [props.marker.position_x, props.marker.position_y, props.marker.position_z]
    const orientation = [props.marker.orientation_x, props.marker.orientation_y, props.marker.orientation_z, "XYZ"]

    

    const handleClick = (e) => {
        if (intersect().length > 0 && intersect()[0].object.name === "marker"){
            e.stopPropagation()
            console.log(intersect()[0])
            if (props.adding === 2) {
                setActive(!active)
                ImageSrc()
                setForm(props.formData.find(x => x.form_id == props.marker.form).name)
                setColor(props.colorData.find(x => x.color_id == props.marker.color).value)
                setSurface(props.surfaceData.find(x => x.surface_id == props.marker.surface).value)
                setConsistency(props.consistencyData.find(x => x.consistency_id == props.marker.consistency).name)
                setEvolutionForm(props.evolutionFormData.find(x => x.evolution_form_id == props.marker.evolution_form).value)
                setSymptomatology(props.symptomatologyData.find(x => x.symptomatology_id == props.marker.symptomatology).value)
                setEdge(props.edgeData.find(x => x.edge_id == props.marker.edge).name)
                setLocalization(props.localizationData.find(x => x.localization_id == props.marker.localization).value)
                setInjuries(props.injuryData.find(x => x.injury_id == props.marker.injury).name)
        }}
    }

    const [src, setSrc] = useState("")
    const [alt, setAlt] = useState("")

    const ImageSrc=()=>{
        if (props.marker.foto === null || props.image.find(image=>image.foto_id === props.marker.foto ) === undefined){
            setSrc(require("assets/img/image-placeholder.png"))} 
        else
        {
            setSrc(process.env.REACT_APP_API_URL + `${props.image.find(image=>image.foto_id === props.marker.foto ).url}` + "/" + `${props.image.find(image=>image.foto_id === props.marker.foto ).foto_nombre}`)
            setAlt(props.image.find(image=>image.foto_id === props.marker.foto ).foto_nombre) 
        }
    }

    const meshSelector = (injury) => {
        switch (injury) {
            case 1:
                return (
                    <mesh
                        {...props}
                        material={materials.no_identificada}
                        geometry={nodes.no_identificada.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                    >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 2:
                return (
                    (props.marker.localization === 18 || props.marker.localization === 19)?(
                        <mesh
                        {...props}
                        material={materials.paladar_lesiones} 
                        geometry={nodes.paladar_lesiones.geometry}
                        scale={[10, 10, 10]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={[0,0.04,0]}
                        rotation={[-1.45,0,0]}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                    ):(
                        (props.marker.localization === 13)?(
                            <mesh
                                {...props}
                                material={materials.lengua_lesiones} 
                                geometry={nodes.lengua_lesiones.geometry}
                                scale={[9.6, 9.6, 9.6]}
                                onClick={(e) => handleClick(e)}
                                onPointerOver={() => setHover(true)}
                                onPointerOut={() => setHover(false)}
                                position={[0,0.1,0.084]}
                                rotation={[-1.25,0,0]}
                                name='marker'
                                >
                                <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                            </mesh>):
                            (<mesh
                                {...props}
                                material={materials.no_identificada}
                                geometry={nodes.no_identificada.geometry}
                                scale={[props.marker.length, props.marker.width, props.marker.height]}
                                onClick={(e) => handleClick(e)}
                                onPointerOver={() => setHover(true)}
                                onPointerOut={() => setHover(false)}
                                position={position}
                                rotation={orientation}
                                name='marker'
                                >
                                <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                            </mesh>)
                    )
                )
            case 3:
                return (
                    
                    <mesh
                        {...props}
                        material={materials.carcinoma}
                        geometry={nodes.carcinoma.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 4:
                return (
                    <mesh 
                        {...props}
                        material={materials.mucosa} 
                        geometry={nodes.epulis.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 5:
                return (
                    <mesh 
                        {...props}
                        material={materials.eritroplasia} 
                        geometry={nodes.eritroplasia.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 6:
                return (<mesh 
                    {...props}
                    material={materials.estomatitis} 
                    geometry={nodes.estomatitis.geometry}
                    scale={[10, 10, 10]}
                    onClick={(e) => handleClick(e)}
                    onPointerOver={() => setHover(true)}
                    onPointerOut={() => setHover(false)}
                    position={[0,0.04,0]}
                    rotation={[-1.45,0,0]}
                    name='marker'
                    >
                    <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                    )
            case 7:
                return (
                    <mesh 
                        {...props}
                        material={materials.mucosa} 
                        geometry={nodes.fibroma.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 8:
                return (
                    <mesh 
                        {...props}
                        material={materials.granuloma}
                        geometry={nodes.granuloma.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 9:
                return (
                    <mesh 
                        {...props}
                        material={materials.mucosa} 
                        geometry={nodes.hiperplasia__papilar.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                    
                )
            case 10:
                return (
                    <mesh 
                        {...props}
                        material={materials.leucoplasia} 
                        geometry={nodes.leucoplasia.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
                
            case 11:
                return (
                    <mesh 
                        {...props}
                        material={materials.liquen_plano} 
                        geometry={nodes.liquen_plano.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 12:
                return (
                    <mesh 
                        {...props}
                        material={materials.hemangioma} 
                        geometry={nodes.hemangioma.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 13:
                return (
                    <mesh 
                        {...props}
                        material={materials.penfigo} 
                        geometry={nodes['penfigo-penfigoide'].geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 14:
                return (
                    <mesh 
                        {...props}
                        material={materials.penfigo} 
                        geometry={nodes['penfigo-penfigoide'].geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 15:
                return (
                    <mesh 
                        {...props}
                        material={materials.pigmentacion_melanica} 
                        geometry={nodes.pigmentacion_melanica.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 16:
                return (
                    <mesh 
                        {...props}
                        material={materials.ulcera} 
                        geometry={nodes.ulcera.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            case 17:
                return (
                    <mesh 
                        {...props}
                        material={materials.sarcoma} 
                        geometry={nodes.sarcoma.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
            default:
                return (
                    <mesh 
                        {...props}
                        material={materials.no_identificada}
                        geometry={nodes.no_identificada.geometry}
                        scale={[props.marker.length, props.marker.width, props.marker.height]}
                        onClick={(e) => handleClick(e)}
                        onPointerOver={() => setHover(true)}
                        onPointerOut={() => setHover(false)}
                        position={position}
                        rotation={orientation}
                        name='marker'
                        >
                        <TagInfo props={props} active={active} setActive={setActive} form={form} color={color} surface={surface} consistency={consistency} evolutionForm={evolutionForm} symptomatology={symptomatology} edge={edge} injury={injuries} localization={localization} src={src} alt={alt}/>
                    </mesh>
                )
                break;
        }
    }

    {/* 
        <mesh material={materials.paladar_lesiones} geometry={nodes.paladar_lesiones.geometry} />

        <mesh material={materials.mejilla_izq_lesiones} geometry={nodes.mejilla_izq_lesiones.geometry} />
        <mesh material={materials.mejilla_der_lesiones} geometry={nodes.mejilla_der_lesiones.geometry} />
        <mesh material={materials.lengua_lesiones} geometry={nodes.lengua_lesiones.geometry} />
    */}

    return (
        <>
            {meshSelector(props.marker.injury)}
        </>
    )
}
export default Tag

useGLTF.preload('/mesh/lesiones.gltf')