import React from 'react'
import Tag from './Tag'

const MarkersGroup = (props) => {

    return (
        <>
            {props.markers.length > 0 ? (
                 props.markers.map(marker => (
                    <Tag 
                        key={marker.id} 
                        marker={marker} 
                        editMarker={props.editMarker}
                        deleteMarker={props.deleteMarker} 
                        orientationHelper={props.orientationHelper} 
                        positionHelper={props.positionHelper} 
                        adding={props.adding}
                        formData={props.formData} 
                        colorData={props.colorData} 
                        surfaceData={props.surfaceData} 
                        consistencyData={props.consistencyData} 
                        evolutionFormData={props.evolutionFormData} 
                        symptomatologyData={props.symptomatologyData} 
                        edgeData={props.edgeData} 
                        localizationData={props.localizationData}
                        injuryData={props.injuryData}
                        image={props.image}
                    />
                ))
            ) : (
                <></>
            )}        
        </>
    )    
}
export default MarkersGroup