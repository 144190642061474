import React, {useState} from 'react'
import {
    Button, Col, Container, Form, FormGroup, Input, Card, CardBody, Label
} from 'reactstrap'

import Axios from '../../../helpers/axiosConfig'
import {Redirect} from 'react-router-dom';

const PatientAssistance = ({location}) => {
    
    const [next, setNext] = useState(false)
    const [acceptedConcentId, setAcceptedConcentId] = useState(false)
    
    const enviar = async (e) => {
        e.preventDefault()
            if(acceptedConcentId == true){
                setNext(true)
            }else{
                setStatusPerson(location.state.id, 5)
                window.location.href = '/recepcionar-paciente';
            }
    }


    const setStatusPerson = async (idPersona, status) => {
        const res = await Axios.put('person/set-status-person/',
        {
            status_id: status,
            personId: idPersona
        }
        )

        if (!res.data.error) {
            console.log('Status actualizado');
        };

    };

    
 
    return (
        <Container>
            <Card>
                <CardBody>
                    <h3>{location.state.run}, {location.state.name} {location.state.apellido}</h3>
                    <h1>
                        Informar asistencia
                    </h1>
                    <Form onSubmit={enviar} role="form">
                        
                        <FormGroup className="row">
                            
                            <Col md="6">
                                <Label
                                    className="form-control-label"
                                    htmslFor="example-text-input"
                                >
                                    Confirmar asistencia
                                </Label>
                                <div><label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" checked={acceptedConcentId} onChange={(e) => { setAcceptedConcentId(e.target.checked)
                                    }}/>
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label></div>
                            </Col>
                        </FormGroup>

                        <FormGroup className="row">
                            <Col md="10">

                            </Col>
                            <Col md="0,5">
                                <Button color="primary" type="submit">Continuar</Button>
                            </Col>

                        </FormGroup>

                    </Form>
                    {next && <Redirect to={{
                        pathname: "/recepcion-consentimiento-informado",
                        state: {
                            id: location.state.id,
                            run: location.state.run,
                            name: location.state.name,
                            apellido: location.state.apellido
                        }
                    }}/>}
                </CardBody>
            </Card>
        </Container>
    )
}

export default PatientAssistance