import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, FormGroup, Input, Card, CardBody, ModalHeader, Label } from 'reactstrap';

const NonCariousInjury = (props) => {

    const { data, setData} = props

    const [nonCarious, setNonCarious] = useState(data)

    const type = [
        { id: 1, value: "Atrición" },
        { id: 2, value: "Abfracción" },
        { id: 3, value: "Erosión" }
    ]

    const surfaces_coronal = [
        { id: "coronal_oclusal", value: "Incisal/Oclusal" },
        { id: "coronal_vestibular", value: "Vestibular" },
        { id: "coronal_palatino", value: "Palatino/Lingual" },
        { id: "coronal_mesial", value: "Mesial" },
        { id: "coronal_distal", value: "Distal" }
    ]

    const surfaces_radicular = [
        { id: "radicular_vestibular", value: "Vestibular" },
        { id: "radicular_palatino", value: "Palatino/Lingual" },
        { id: "radicular_mesial", value: "Mesial" },
        { id: "radicular_distal", value: "Distal" }
    ]


    const handleInputChange = (e) => {
        const {id, value} = e.target
        setNonCarious({...nonCarious, [id]: value})
    }
    const handleInputCheckbox=(e) =>{
        const {id, checked} = e.target
        setNonCarious({...nonCarious, surface: {...nonCarious.surface, [id]: checked} })
    }

    useEffect(() => {
        setData(nonCarious)
    }, [nonCarious])


    return (
        <FormGroup className="row m-0">
            <Col md="12">
                <FormGroup className="row">
                    <Col sm={12}>
                        <Label className="form-control-label">Tipo de lesión no cariosa</Label>
                        <Input
                            bsSize="sm"
                            id="type"
                            name="type"
                            type="select"
                            value={nonCarious.type}
                            onChange={e => handleInputChange(e)}>
                            <option hidden value="">Seleccionar</option>
                            {type.map((item) =>
                                <option
                                    key={item.id}
                                    value={item.value}
                                >
                                    {item.value}
                                </option>
                            )
                            }
                        </Input>
                    </Col>

                </FormGroup>
                <Label className="form-control-label">Ubicación/Superficie</Label>
                <FormGroup className="row">
                    <Col sm={6}>
                    <Label className="form-control-label">Coronal</Label>
                        <Col sm={12}>
                            {surfaces_coronal.map((item) =>
                                <div key={item.id} className="custom-control custom-checkbox custom-checkbox-primary">
                                    <Input
                                        className="custom-control-input"
                                        id={item.id}
                                        name={item.id}
                                        type="checkbox"
                                        checked={nonCarious.surface[item.id]}
                                        onChange={(e) => {
                                            handleInputCheckbox(e)
                                        }}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={item.id}
                                    >{item.value}</label>
                                </div>)
                            }
                        </Col>
                    </Col>
                    <Col sm={6}>
                        <Label className="form-control-label">Radicular</Label>
                        <Col sm={12}>
                            {surfaces_radicular.map((item) =>
                                <div key={item.id} className="custom-control custom-checkbox custom-checkbox-primary">
                                    <Input
                                        className="custom-control-input"
                                        id={item.id}
                                        name={item.id}
                                        type="checkbox"
                                        checked={nonCarious.surface[item.id]}
                                        onChange={(e) => {
                                            handleInputCheckbox(e)
                                        }}
                                    />
                                    <label
                                        className="custom-control-label"
                                        htmlFor={item.id}
                                    >{item.value}</label>
                                </div>)
                            }
                        </Col>
                    </Col>
                </FormGroup>
            </Col>
        </FormGroup>
    )
}

export default NonCariousInjury



