import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Container, FormGroup, Label, Form, Card, CardBody, Input, Alert } from 'reactstrap';
import '../../../assets/css/download-data.css';
import axios from '../../../helpers/axiosConfig';
import * as XLSX from 'xlsx';

const DownloadData = () => {
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [dateError, setDateError] = useState(false);
    const [dataError, setDataError] = useState(false);
    const [downloadMsg, setDownloadMsg] = useState(false);
    const [message, setMessage] = useState('');
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDateError(false);
            setDataError(false);
            setDownloadMsg(false);
        }, 10000);
        return () => clearTimeout(timer);
    }, [dateError, dataError, downloadMsg]);

    const downloadData = async () => {

        setDownloading(true);

        if (!dateFrom || !dateTo) {
            setDateError(true);
            setMessage("Por favor, seleccione las fechas antes de descargar los datos.");
            setDownloading(false);
            return;
        }

        if (dateFrom > dateTo) {
            setDateError(true);
            setMessage("La fecha de inicio no puede ser posterior a la fecha de término.");
            setDownloading(false);
            return;
        }

        try {

            const response = await axios.post("download-data/get", { dateFrom, dateTo });
            const data = response.data.result;

            if (data.length === 0) {
                setDataError(true);
                setMessage("No existen datos en el rango de fecha establecido.");
                setDownloading(false);
                return;
            }

            // Creamos un nuevo libro de trabajo
            const workbook = XLSX.utils.book_new();

            // Convertimos los datos a un formato que xlsx puede entender
            const worksheet_data = XLSX.utils.json_to_sheet(data);

            // Añadimos los datos como una nueva hoja al libro
            XLSX.utils.book_append_sheet(workbook, worksheet_data, "Data");

            XLSX.writeFile(workbook, "descarga_patologias_desde_"+dateFrom+"_hasta_"+dateTo+".xlsx");
            
            setDownloadMsg(true)
            setMessage("Descarga realizada correctamente.");
            
            setDownloading(false);

        } catch (error) {

            setDataError(true);
            setMessage("Ha ocurrido un error.");
            setDownloading(false);
            console.log(error)
        }

    };

    const reqTag = <span style={{ color: "#DC3545" }}>*</span>

    return (
        <Container className="mt-3">
            <Card>
                <CardBody>

                    {dateError && (<Alert color="danger" className="fade-out"> <strong><i className="fa fa-info-circle" /> {message}</strong></Alert>)}
                    {dataError && <Alert color="danger" className="fade-out"><strong><i className="fa fa-info-circle" /> {message}</strong></Alert>}
                    {downloadMsg && <Alert color="success" className="fade-out"><strong><i className="fa fa-download" /> {message}</strong></Alert>}
                    
                    <h1>Descargar datos de Patologías</h1>
                    <h5 style={{ color: "#DC3545" }}>* Existen campos obligatorios</h5>
                    <Form role="form">
                        <FormGroup className="row">
                            <Col md="10">
                                <Label className="form-control-label"> Fecha de inicio {reqTag}</Label>
                                <Input
                                    required
                                    type="date"
                                    id="dateFrom"
                                    value={dateFrom}
                                    onChange={e => setDateFrom(e.target.value)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="10">
                                <Label className="form-control-label"> Fecha de término {reqTag}</Label>
                                <Input
                                    required
                                    type="date"
                                    id="dateTo"
                                    value={dateTo}
                                    onChange={e => setDateTo(e.target.value)}
                                />
                            </Col>
                        </FormGroup>
                        {downloading && <strong><i className="fa fa-download" /> La descarga se está procesando...</strong>}
                        <FormGroup className="row">
                            <Col md="10"></Col>
                            <Col md="2">
                                <Button onClick={downloadData} color="primary" disabled={downloading}><i className="fa fa-download" /> Descargar</Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </CardBody>
            </Card>
        </Container>
    );
};

export default DownloadData;
