import React from "react";

function Legend() {

    const colorLegend = {
        1: '#f5f0f1', //ausente 
        2: '#F4F2E5', //completo
        3: '#ff8080', //fracturado
        4: '#c4e5c9', //resto radicular
        5: '#878681' //implante
    }



    return (
        <g fillOpacity="1" transform="translate(-35.628 -25.806)">
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="35.006"
                    y="104.605"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="35.006" y="104.605" strokeWidth="0.265" fontSize="4.586">
                        1.8
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="38.168"
                    y="88.19"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="38.168" y="88.19" strokeWidth="0.265" fontSize="4.586">
                        1.7
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="42.037"
                    y="74.415"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="42.037" y="74.415" strokeWidth="0.265" fontSize="4.586">
                        1.6
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="47.549"
                    y="62.011"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="47.549" y="62.011" strokeWidth="0.265" fontSize="4.586">
                        1.5
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="53.338"
                    y="51.812"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="53.338" y="51.812" strokeWidth="0.265" fontSize="4.586">
                        1.4
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="64.088"
                    y="39.409"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="64.088" y="39.409" strokeWidth="0.265" fontSize="4.586">
                        1.3
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="77.318"
                    y="31.14"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="77.318" y="31.14" strokeWidth="0.265" fontSize="4.586">
                        1.2
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="92.478"
                    y="29.21"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="92.478" y="29.21" strokeWidth="0.265" fontSize="4.586">
                        1.1
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="105.433"
                    y="29.21"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="105.433" y="29.21" strokeWidth="0.265" fontSize="4.586">
                        2.1
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="121.42"
                    y="31.691"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="121.42" y="31.691" strokeWidth="0.265" fontSize="4.586">
                        2.2
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="135.753"
                    y="41.338"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="135.753" y="41.338" strokeWidth="0.265" fontSize="4.586">
                        2.3
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="146.415"
                    y="54.613"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="146.415" y="54.613" strokeWidth="0.265" fontSize="4.586">
                        2.4
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="151.74"
                    y="66.145"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="151.74" y="66.145" strokeWidth="0.265" fontSize="4.586">
                        2.5
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="155.048"
                    y="78.549"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="155.048" y="78.549" strokeWidth="0.265" fontSize="4.586">
                        2.6
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="161.387"
                    y="92.055"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="161.387" y="92.055" strokeWidth="0.265" fontSize="4.586">
                        2.7
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="161.663"
                    y="105.01"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="161.663" y="105.01" strokeWidth="0.265" fontSize="4.586">
                        2.8
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="38.995"
                    y="150.991"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="38.995" y="150.991" strokeWidth="0.265" fontSize="4.586">
                        4.8
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="42.027"
                    y="166.955"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="42.027" y="166.955" strokeWidth="0.265" fontSize="4.586">
                        4.7
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="45.886"
                    y="181.631"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="45.886" y="181.631" strokeWidth="0.265" fontSize="4.586">
                        4.6
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="51.674"
                    y="194.31"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="51.674" y="194.31" strokeWidth="0.265" fontSize="4.586">
                        4.5
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="58.565"
                    y="205.335"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="58.565" y="205.335" strokeWidth="0.265" fontSize="4.586">
                        4.4
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="69.106"
                    y="215.258"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="69.106" y="215.258" strokeWidth="0.265" fontSize="4.586">
                        4.3
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="81.994"
                    y="221.322"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="81.994" y="221.322" strokeWidth="0.265" fontSize="4.586">
                        4.2
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="93.571"
                    y="222.932"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="93.571" y="222.932" strokeWidth="0.265" fontSize="4.586">
                        4.1
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="103.77"
                    y="222.954"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="103.77" y="222.954" strokeWidth="0.265" fontSize="4.586">
                        3.1
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="114.483"
                    y="221.2"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="114.483" y="221.2" strokeWidth="0.265" fontSize="4.586">
                        3.2
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="126.85"
                    y="215.239"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="126.85" y="215.239" strokeWidth="0.265" fontSize="4.586">
                        3.3
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="138.512"
                    y="208.015"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="138.512" y="208.015" strokeWidth="0.265" fontSize="4.586">
                        3.4
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="145.773"
                    y="196.569"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="145.773" y="196.569" strokeWidth="0.265" fontSize="4.586">
                        3.5
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="153.114"
                    y="180.943"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="153.114" y="180.943" strokeWidth="0.265" fontSize="4.586">
                        3.6
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="157.482"
                    y="167.24"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="157.482" y="167.24" strokeWidth="0.265" fontSize="4.586">
                        3.7
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="159.791"
                    y="151.464"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="159.791" y="151.464" strokeWidth="0.265" fontSize="4.586">
                        3.8
                    </tspan>
                </text>
                <rect
                    width="9.071"
                    height="11.403"
                    x="78.771"
                    y="81.868"
                    fill={colorLegend[2]}
                    stroke="#6a6565"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth= "0.265"
                    opacity="1"
                    ry="4.535"
                ></rect>
                <rect
                    width="9.071"
                    height="11.403"
                    x="78.771"
                    y="100.389"
                    fill={colorLegend[3]}
                    stroke="#6a6565"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth= "0.265"
                    opacity="1"
                    ry="4.535"
                ></rect>
                <rect
                    width="9.071"
                    height="11.403"
                    x="78.771"
                    y="118.909"
                    fill={colorLegend[4]}
                    stroke="#6a6565"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth= "0.265"
                    opacity="1"
                    ry="4.535"
                ></rect>
                <rect
                    width="9.071"
                    height="11.403"
                    x="78.771"
                    y="137.43"
                    fill={colorLegend[5]}
                    stroke="#6a6565"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth= "0.265"
                    opacity="1"
                    ry="4.535"
                ></rect>
                <rect
                    width="9.071"
                    height="11.403"
                    x="78.771"
                    y="155.951"
                    fill={colorLegend[1]}
                    stroke={colorLegend[1]}
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth= "0.265"
                    opacity="1"
                    ry="4.535"
                ></rect>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="91.622"
                    y="88.278"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="91.622" y="88.278" strokeWidth="0.265" fontSize="4.586">
                        Completo
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="91.966"
                    y="106.884"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="91.966" y="106.884" strokeWidth="0.265" fontSize="4.586">
                        Fracturado
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="91.857"
                    y="125.814"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="91.857" y="125.814" strokeWidth="0.265" fontSize="4.586">
                        Resto radicular
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="91.351"
                    y="144.474"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="91.351" y="144.474" strokeWidth="0.265" fontSize="4.586">
                        Implante
                    </tspan>
                </text>
                <text
                    xmlSpace="preserve"
                    style={{ lineHeight: "1.25" }}
                    x="92.4"
                    y="162.723"
                    fill="#000"
                    stroke="none"
                    strokeWidth="0.265"
                    fontFamily="sans-serif"
                    fontSize="10.583"
                    fontStyle="normal"
                    fontWeight="normal"
                    letterSpacing="0"
                    wordSpacing="0"
                >
                    <tspan x="92.4" y="162.723" strokeWidth="0.265" fontSize="4.586">
                        Ausente
                    </tspan>
                </text>
        </g>
    );
}

export default Legend;
