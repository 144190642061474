import Dashboard from "views/pages/dashboards/Dashboard.js";
import ListPatient from "views/pages/reception/list-patient";
import ListRecord from "views/pages/clinical-record/list-records"
import Referral from "views/pages/referral/referral"
import ListPatientAnamnesis from "views/pages/anamnesis/list-patient-anamnesis"

const routes = [
  {
    path: "/agenda",
    name: "Agenda",
    icon: "fa fa-columns text-primary",
    miniName: "D",
    component: Dashboard,
    layout: "/dentist" 
  },
  {
    path: "/recepcionar-paciente",
    name: "Recepcionar paciente",
    miniName: "LP",
    icon: "ni fas fa-user-plus text-primary",
    component: ListPatient,
    layout: "/dentist"
  },
  {
    path: "/anamnesis-pacientes",
    name: "Crear Anamnesis ",
    miniName: "LPA",
    icon: "ni fas fa-user-plus text-primary",
    component: ListPatientAnamnesis,
    layout: "/dentist"
  },
  {
    path: "/interconsultas",
    name: "Interconsultas",
    icon: "fa fa-address-book text-primary",
    miniName: "IC",
    component: Referral,
    layout: "/dentist" 
  },
  {
    path: "/fichas-clinicas",
    name: "Fichas clinicas",
    miniName: "LF",
    icon: "ni fas fa-user-plus text-primary",
    component: ListRecord,
    layout: "/dentist"
    
  }
];

export default routes;