import React, { useState, useRef, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Col, InputGroup,
        Card, CardHeader, CardBody, InputGroupAddon, Progress } from 'reactstrap';
import moment from 'moment';
import axios from '../../../../helpers/axiosConfig';

const EditControlModal = ({ editModal, setEditModal, editingControl, handleInputChangeControl, handleEditControl, run }) => {
    
    const modalRef = useRef()

    const setModal = (options) => {
        if (modalRef.current) {
            modalRef.current.setModal(options);
        }
    };    
    
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");
    const [file, setFile] = useState({});
    const [hasFile, setHasFile] = useState(false);
    const [files, setFiles] = useState([]);
    const extAllowed = "jpeg jpg png pdf";
    const sizAllowed = 120000000;
    const defaultHelpText = extAllowed + " (max: " + sizAllowed / 1000000 + " MB)";
    const [helpText, setHelpText] = useState(defaultHelpText);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);

    const handleHasFile = () => {
        setHasFile(!hasFile);
        cleanFile();
        setHelpText(defaultHelpText);
    };

    const fetchFiles = async () => {
        try {
            const res = await axios.post("photo/anamnesis_general_control/obtain", {
                anamnId: editingControl.general_anamnesis_control_id,
            });
            if (!res.data.error) {
                setFiles(res.data.result);
                setModal({});
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
    };

    const loadFile = (file) => {

        if (file[0]?.name) {
            const size = file[0].size;
            const full = file[0]?.name;
            const type = full
                .slice(((full.lastIndexOf(".") - 1) >>> 0) + 2)
                .toLowerCase();
            if (extAllowed.indexOf(type) >= 0) {
                if (size <= sizAllowed) {
                    setFileName(full.slice(0, full.lastIndexOf(".")));
                    setFileType(type);
                    setFile(file);
                    setHelpText(full);
                    return;
                } else {
                    cleanFile();
                    setHelpText("Tamaño de archivo invalido");
                    return;
                }
            }
            cleanFile();
            setHelpText("Formato de archivo invalido");
        }

    };

    const cleanFile = () => {
        setFileName("");
        setFileType("");
        setFile({});
    };

    const uploadFile = () => {
        if (fileName.length > 60) {
            setHelpText(
                "El archivo excede el maximo de caracteres permitidos. (max:60)"
            );
        } else if (fileName) {
            setShowProgress(true);
            const f = new FormData();
            f.append("file", file[0], fileName + "." + fileType);
            const config = {
                onUploadProgress: (progressEvent) => {
                    let percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadProgress(percentCompleted);
                },
            };

            axios
                .post("file/img_general_control/" + run, f, config)
                .then((response) => {
                    saveFile();
                    setUploadProgress(0);
                    setShowProgress(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const saveFile = async () => {
        const res = await axios.post("photo/anamnesis_general_control", {
            anamnId: editingControl.general_anamnesis_control_id,
            nombreImagen: fileName + "." + fileType,
            ruta: "static/images_anam_general_control/" + run,
        });
        if (!res.data.error) {
            fetchFiles();
            cleanFile();
            setHelpText(defaultHelpText);
            setHasFile(false);
        }
    };

    const removeFile = async (fId, name) => {
        const res = await axios.post("photo/anamnesis_general_control/rm/", {
            fId: fId,
            route: run + "/" + name,
        });
        if (!res.data.error) {
            fetchFiles();  // Recargar la lista de archivos
        } else {
            console.error("Error eliminando archivo:", res.data.error);
            // Opcional: Mostrar un mensaje de error
        }
    };

    useEffect(() => {
        if (editModal) {
            fetchFiles();
        }
    }, [editModal]);

    useEffect(() => {
        if (editModal) {
            setActiveTab('control');
        }
    }, [editModal]);
    
    const [activeTab, setActiveTab] = useState('control');

    return (
        <Modal isOpen={editModal} toggle={() => setEditModal(!editModal)} size="lg">
            <ModalHeader toggle={() => setEditModal(!editModal)}>
                <div className="tab-selector">
                    <a 
                        onClick={() => setActiveTab('control')} 
                        style={{ 
                            paddingRight: "15px", 
                            cursor: "pointer", 
                            color: activeTab === 'control' ? "#11cdef" : "inherit" 
                        }}
                    >
                        Control
                    </a>
                    <a 
                        onClick={() => setActiveTab('repository')} 
                        style={{ 
                            paddingRight: "15px", 
                            cursor: "pointer", 
                            color: activeTab === 'repository' ? "#11cdef" : "inherit"
                        }}
                    >
                        Repositorio
                    </a>
                </div>
            </ModalHeader>
            <ModalBody>
                {activeTab === 'control' && (
                    <div>
                        <FormGroup className="row">
                        <Col md="6">
                            <Label className="form-control-label">Profesional encargado</Label>
                            <Input
                                type="text"
                                name="assignedProfessional"
                                value={editingControl?.professional_name}
                                disabled
                            >
                            </Input>
                        </Col>
                        <Col md="6">
                            <Label className="form-control-label">Fecha</Label>
                            <Input
                                type="date"
                                value={moment(editingControl?.control_date).format('YYYY-MM-DD')}
                                name="controlDate"
                                disabled
                            />
                        </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="12">
                                <Label className="form-control-label">Valoración</Label>
                                <Input
                                    autoComplete="off" maxLength="250"
                                    type="textarea"
                                    name="assessment"
                                    value={editingControl?.assessment}
                                    onChange={handleInputChangeControl}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="12">
                                <Label className="form-control-label">Descripción</Label>
                                <Input
                                    autoComplete="off" maxLength="250"
                                    type="textarea"
                                    name="description"
                                    value={editingControl?.description}
                                    onChange={handleInputChangeControl}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="12">
                                <Label className="form-control-label">Tratamiento realizado</Label>
                                <Input
                                    autoComplete="off" maxLength="250"
                                    type="textarea"
                                    name="treatment_performed"
                                    value={editingControl?.treatment_performed}
                                    onChange={handleInputChangeControl}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="12">
                                <Label className="form-control-label">Apósito Secundario Utilizado</Label>
                                <Input
                                    autoComplete="off" maxLength="250"
                                    type="textarea"
                                    name="secondary_dressing_used"
                                    value={editingControl?.secondary_dressing_used}
                                    onChange={handleInputChangeControl}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="12">
                                <Label className="form-control-label">Apósito Pasivo Utilizado</Label>
                                <Input
                                    autoComplete="off" maxLength="250"
                                    type="textarea"
                                    name="passive_dressing_used"
                                    value={editingControl?.passive_dressing_used}
                                    onChange={handleInputChangeControl}
                                />
                            </Col>
                        </FormGroup>
                    </div>
                )}
                {activeTab === 'repository' && (
                    <div>
                        <FormGroup className="row">
                            <Col md="6">
                                <Label className="form-control-label mb-3">
                                    Archivos disponibles
                                </Label>
                                {files.map((file) => (
                                    <div key={file.foto_id} className="list-group mb-2">
                                        <InputGroup>
                                            <Button
                                                className="mr-2"
                                                color="danger"
                                                size="sm"
                                                outline
                                                onClick={() => removeFile(file.foto_id, file.foto_nombre)}
                                            >
                                                <i className="far fa-trash-alt" />
                                            </Button>
                                            <a
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                href={
                                                    process.env.REACT_APP_API_URL +
                                                    `${file.url}` +
                                                    "/" +
                                                    `${file.foto_nombre}`
                                                }
                                            >
                                                {file.foto_nombre}
                                            </a>
                                        </InputGroup>
                                    </div>
                                ))}
                            </Col>
                            <Col md="6">
                                <Card>
                                    <CardHeader>
                                        <FormGroup className="row">
                                            <Col className="d-flex">
                                                <Label className="form-control-label">
                                                    ¿Subir una nuevo archivo?
                                                </Label>
                                                <label className="custom-toggle custom-toggle-info ml-3">
                                                    <Input
                                                        type="checkbox"
                                                        checked={hasFile}
                                                        onChange={() => handleHasFile()}
                                                    />
                                                    <span
                                                        className="custom-toggle-slider rounded-circle"
                                                        data-label-off="No"
                                                        data-label-on="Sí"
                                                    />
                                                </label>
                                            </Col>
                                        </FormGroup>
                                    </CardHeader>
                                    {hasFile && (
                                        <CardBody>
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            onClick={() => uploadFile()}
                                                        >
                                                            Agregar
                                                        </Button>
                                                    </InputGroupAddon>
                                                    <Input
                                                        autoComplete="off"
                                                        type="text"
                                                        name="treatment_plan"
                                                        value={fileName}
                                                        onChange={(e) => setFileName(e.target.value)}
                                                        disabled
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            onClick={() => {
                                                                document.getElementById("files").click();
                                                            }}
                                                        >
                                                            Buscar
                                                        </Button>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                                <h5
                                                    align="right"
                                                    style={{
                                                        marginRight: "10px",
                                                        marginBottom: "0px",
                                                    }}
                                                >
                                                    {helpText}
                                                </h5>
                                                {showProgress && (
                                                    <Progress
                                                        value={uploadProgress}
                                                        animated
                                                        color="success"
                                                    >
                                                        {uploadProgress}%
                                                    </Progress>
                                                )}
                                                <input
                                                    type="file"
                                                    accept="image/*, .pdf"
                                                    id="files"
                                                    style={{ visibility: "hidden" }}
                                                    onChange={(e) => loadFile(e.target.files)}
                                                />
                                            </FormGroup>
                                        </CardBody>
                                    )}
                                </Card>
                            </Col>
                        </FormGroup>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleEditControl}>Guardar Cambios</Button>
                <Button color="secondary" onClick={() => setEditModal(false)}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    );
};

export default EditControlModal;
