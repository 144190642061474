import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function FraxIntroductionModal(args) {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <>
            <a className='text-warning' onClick={toggle}>
                Recordatorio
            </a>
            <Modal isOpen={modal} toggle={toggle} {...args}>
                <ModalHeader toggle={toggle}><i className="fa fa-info-circle" /> Instrucciones</ModalHeader>
                <ModalBody>
                    <p style={{ textAlign: 'justify' }}>
                        No olvides configurar la herramienta de cálculo de la siguiente manera: <br/>
                        <br/>
                        1. Dirígete a la barra superior y selecciona la opción "Herramientas de cálculo".<br/>
                        2. Coloca el cursor sobre la opción "América Latina".<br/>
                        3. Selecciona la opción "Chile".<br/>
                        <br/>
                        Al hacer esto, se usarán las medidas de curso nacional (Chile) al realizar el cálculo.
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-primary" onClick={toggle}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default FraxIntroductionModal;