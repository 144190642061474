import React, { useState, useEffect, Suspense } from 'react'
import Axios from '../../../helpers/axiosConfig'
import { Button, ButtonGroup, ButtonToolbar, Tooltip, Container } from 'reactstrap'
import { Canvas } from "react-three-fiber";
import CameraControls from '../../../components/Fantoma/CameraControls'
import FantomaOdontogram  from '../../../components/Fantoma/FantomaOdontogram'
import OdontogramModal  from '../../../components/Fantoma/FormsExams/OdontogramModal'
import { Link } from 'react-router-dom';

const ToothPicker = ({location, update}) => {
    const [canvasSize, setCanvasSize] = useState({height: undefined}); 
    const [gumOpacity, setGumOpacity] = useState(true)
    const [showModal, setShowModal] = useState(false)
	const [piecesTooltipOpen, setPiecesTooltipOpen] = useState(false);
    const [opacityTooltipOpen, setOpacityTooltipOpen] = useState(false);

    const resizeCanvas=()=>{
		const navbarSize = document.getElementsByClassName('navbar-top')[0];
		//const footerSize = document.getElementsByClassName('footer')[0];
		const handleResize = () => {
			setCanvasSize({
				width: window.innerWidth -(update !== undefined  ? (230):(0)) - navbarSize.offsetWidth ,
				height: window.innerHeight -(update !== undefined  ? (230):(0)) - navbarSize.offsetHeight
			});
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize); 
    }

    const initialTeethStatus = [
        { fdi_name: "1.1", tooth_state_id: 1 },
        { fdi_name: "1.2", tooth_state_id: 1 },
        { fdi_name: "1.3", tooth_state_id: 1 },
        { fdi_name: "1.4", tooth_state_id: 1 },
        { fdi_name: "1.5", tooth_state_id: 1 },
        { fdi_name: "1.6", tooth_state_id: 1 },
        { fdi_name: "1.7", tooth_state_id: 1 },
        { fdi_name: "1.8", tooth_state_id: 1 },
        { fdi_name: "2.1", tooth_state_id: 1 },
        { fdi_name: "2.2", tooth_state_id: 1 },
        { fdi_name: "2.3", tooth_state_id: 1 },
        { fdi_name: "2.4", tooth_state_id: 1 },
        { fdi_name: "2.5", tooth_state_id: 1 },
        { fdi_name: "2.6", tooth_state_id: 1 },
        { fdi_name: "2.7", tooth_state_id: 1 },
        { fdi_name: "2.8", tooth_state_id: 1 },
        { fdi_name: "3.1", tooth_state_id: 1 },
        { fdi_name: "3.2", tooth_state_id: 1 },
        { fdi_name: "3.3", tooth_state_id: 1 },
        { fdi_name: "3.4", tooth_state_id: 1 },
        { fdi_name: "3.5", tooth_state_id: 1 },
        { fdi_name: "3.6", tooth_state_id: 1 },
        { fdi_name: "3.7", tooth_state_id: 1 },
        { fdi_name: "3.8", tooth_state_id: 1 },
        { fdi_name: "4.1", tooth_state_id: 1 },
        { fdi_name: "4.2", tooth_state_id: 1 },
        { fdi_name: "4.3", tooth_state_id: 1 },
        { fdi_name: "4.4", tooth_state_id: 1 },
        { fdi_name: "4.5", tooth_state_id: 1 },
        { fdi_name: "4.6", tooth_state_id: 1 },
        { fdi_name: "4.7", tooth_state_id: 1 },
        { fdi_name: "4.8", tooth_state_id: 1 }
    ]

    const [odontogramId, setOdontogramId] = useState(0)
    const [teethStatus, setTeethStatus] = useState(initialTeethStatus)


    const getOdontogramId = async () => {
        const res = await Axios.get("odontogram/" + location.state.anamId)
        .then(response => {
            if(response.data.result[0] !== undefined){
                //console.log(teethState)
            setOdontogramId(response.data.result[0].odontogram_id)
            getTeethStatus(response.data.result[0].odontogram_id)
            }
        }) 
    } 


    const handleCreateOdontogram =()=>{
        /* getOdontogram() */
        if (odontogramId === 0){
            addTeethStatus(teethStatus)
            console.log('odontograma creado')
        } 
        setShowModal(true)
        /* getOdontogram() */
    }

    const addTeethStatus = async (initialTeeth) => {
		const res = await Axios.post("odontogram",
			{
				dentalAnamnesisId : location.state.anamId,
				teethState: initialTeeth, 
				odontogramId: odontogramId,
			}
		)	
		if (!res.data.error){
            /* alert("Odontograma creado") */
        getOdontogramId()
        //getMarkers()
    }
        
    }
    console.log(teethStatus.map(item => item.fdi_name))

    
	const updateOdontogram = async (id, updatedOdontogram) => {
        console.log(id, updatedOdontogram)
        const res = await Axios.put("odontogram",
            {
                dentalAnamnesisId : location.state.anamId,
                odontogramId: id,
                teethState: updatedOdontogram
                
            }
        )
        if (!res.data.error){
            //setTeeth(Object.values(updatedOdontogram))
            //getMarkers()
            //alert("Etiqueta actualizada")
        }
    }

    const getTeethStatus = async (odontogramId) => {
        const res = await Axios.get("odontogram/teeth/" + odontogramId).then(response => {
            if(response.data.result[0] !== undefined){
                //console.log(response.data.result)
                setTeethStatus(response.data.result)
            }
        }) 
    } 

    console.log(teethStatus)

 	// const deleteOdontogram = async (id) => {
    //     const res = await Axios.delete("odontogram/" + id);
    //     if (!res.data.error) {
    //         alert("Estado dental eliminado")
    //     }
    // }

	useEffect(() => {
        resizeCanvas()
        getOdontogramId()
        //getMarkers()
	}, []);


	return (
		<Container>


        
			<OdontogramModal 
                showModal={showModal} 
                setShowModal={setShowModal} 
                updateOdontogram={updateOdontogram} 
                getOdontogramId={getOdontogramId} 
                teethStatus={teethStatus}
                setTeethStatus={setTeethStatus}
                odontogramId={odontogramId} 
                getTeethState={getTeethStatus}
            />
			<ButtonToolbar>
                <ButtonGroup style={{ zIndex: 100, position: 'absolute', top:'90%', left:'50%', transform:'translate(-50%, -50%)' }}>
                    <Button onClick={() => handleCreateOdontogram()} color="primary" size="lg" id="pieces-selector"><i className="fas fa-tooth"></i>
                        <Tooltip
                            placement="top"
                            isOpen={piecesTooltipOpen}
                            target={"pieces-selector"}
                            toggle={() => setPiecesTooltipOpen(!piecesTooltipOpen)}
                        >
                            Selector de piezas
                        </Tooltip>
                    </Button>
                    <Button onClick={() =>setGumOpacity(!gumOpacity)} color="primary" size="lg" id="opacity"><Icon className="fas"/>
                        <Tooltip
                            placement="top"
                            isOpen={opacityTooltipOpen}
                            target={"opacity"}
                            toggle={() => setOpacityTooltipOpen(!opacityTooltipOpen)}
                        >
                            Opacidad
                        </Tooltip> 
                    </Button>
                </ButtonGroup>
            </ButtonToolbar>
            {!update
                &&
                <ButtonGroup style={{ zIndex: 100, position: 'absolute', top:'90%', left:'95%', transform:'translate(-100%, -50%)' }}>
                    <Button color="primary" size="sm"><Link style={{color: "white"}} to={{
                        state: {
                            id: location?.state?.id,
                            run: location?.state?.run,
                            name: location?.state?.name,
                            apellido: location?.state?.apellido,
                            anamId: location?.state?.anamId
                        },
                        pathname: "/examen-extra-oral"
                        }}>Extra Oral</Link>
                    </Button>
                    <Button color="primary" size="sm"><Link style={{color: "white"}} to={{
                        state: {
                            id: location?.state?.id,
                            run: location?.state?.run,
                            name: location?.state?.name,
                            apellido: location?.state?.apellido,
                            anamId: location?.state?.anamId
                        },
                        pathname: "/examen-tejidos-blandos"
                        }}>Tejidos Blandos</Link>
                    </Button>
                    <Button color="primary" active size="sm">Selector piezas Dentales</Button>
                    <Button color="primary" size="sm"><Link style={{color: "white"}} to={{
                        state: {
                            id: location?.state?.id,
                            run: location?.state?.run,
                            name: location?.state?.name,
                            apellido: location?.state?.apellido,
                            anamId: location?.state?.anamId
                        },
                        pathname: "/odontograma"
                        }}>Odontograma</Link>
                    </Button>
                    <Button color="info" size="sm"><Link style={{color: "white"}} to={{
                        state: {
                            id: location?.state?.id,
                            run: location?.state?.run,
                            name: location?.state?.name,
                            apellido: location?.state?.apellido,
                            anamId: location?.state?.anamId
                        },
                        pathname: "/periodontograma"
                        }}>Periodontograma</Link>
                    </Button>
                </ButtonGroup>
        }
			<Canvas 
                className="canvas"
				gl={{ antialias: true }}
				shadowMap
				pixelRatio={window.devicePixelRatio}
				style={{ height: canvasSize.height }}
				camera={{ fov: 55, position: [0, 0, 10] }}
			>
				<CameraControls minDistance={0.5} maxDistance={1.5}/>
				<spotLight intensity={0.4} position={[300, 300, 2000]} castShadow={true} />
				<spotLight intensity={0.35} position={[-3000, 1300, -1000]} castShadow={true} />
				<ambientLight intensity={0.07} />
				<Suspense fallback="loading">
					<FantomaOdontogram scale={[10, 10, 10]} position={[0, 0, 0]} teeth={teethStatus} opacity={gumOpacity ? 1 : 0.8}/>
				</Suspense>
				{/* <Stats /> */}
			</Canvas>
		</Container>
	)
}

export default ToothPicker


const Icon =()=> {
	return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1rem"
            height="1rem"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 90 90"
            xmlSpace="preserve"
        >
            <path 
                fill='white'
                d="M69.772 20.224C63.521 7.917 50.719 0 36.883 0 16.546 0 0 16.546 0 36.883c0 13.836 7.917 26.638 20.224 32.889C26.32 81.761 38.771 90 53.117 90 73.454 90 90 73.454 90 53.117c0-14.347-8.239-26.797-20.228-32.893zm-16.717 1.011l15.71 15.71a31.866 31.866 0 01-.66 6.411L46.644 21.895a31.874 31.874 0 016.411-.66zm-29.28 44.35a31.458 31.458 0 01-1.51-4.43l6.59 6.59a31.559 31.559 0 01-4.871-1.698c-.069-.154-.143-.306-.209-.462zm13.17 3.18l-15.71-15.71c.004-2.196.231-4.34.66-6.412l21.461 21.461c-2.071.43-4.215.657-6.411.661zm11.833-2.309L23.544 41.222a31.773 31.773 0 012.365-4.707l27.577 27.577a31.925 31.925 0 01-4.708 2.364zm8.797-5.345L28.889 32.425a32.082 32.082 0 013.536-3.536l28.686 28.686a32.082 32.082 0 01-3.536 3.536zm6.517-7.626L36.515 25.908a31.816 31.816 0 014.707-2.364l25.234 25.234a31.768 31.768 0 01-2.364 4.707zm-2.937-31.22a31.469 31.469 0 014.892 1.719 31.559 31.559 0 011.698 4.871zM53.117 85c-10.216 0-19.319-4.835-25.158-12.331a36.842 36.842 0 008.924 1.098c20.338 0 36.884-16.546 36.884-36.883 0-3.047-.377-6.028-1.098-8.924C80.165 33.798 85 42.901 85 53.117 85 70.697 70.697 85 53.117 85z">
            </path>
        </svg>
	);
}