import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "../routes/enroller.routes";
import ConsultationMotive from "views/pages/admission/consultation-motive";
import CovidRisk from "views/pages/admission/covid-risk";
import SociodemographicData from "views/pages/admission/sociodemographic-data";
import InformedConsentReception from "views/pages/reception/informed-consent-reception";
import InformedConsent from "views/pages/admission/informed-consent";
import PatientCarer from "views/pages/admission/patient-carer";
import PatientAdmission from "views/pages/reception/patient-admission";

class Enroller extends React.Component {
  state = {
    sidenavOpen: true
  };
  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/enroller") {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  
  toggleSidenav = e => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    });
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/tego.svg"),
            imgAlt: "..."
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Route path="/ingreso-paciente" component={PatientAdmission}/>
            <Route path="/motivo-consulta" component={ConsultationMotive}/> 
            <Route path="/riesgo-covid" component={CovidRisk}/> 
            <Route path="/consentimiento-informado" component={InformedConsent}/> 
            <Route path="/datos-sociodemograficos" component={SociodemographicData}/> 
            <Route path="/recepcion-consentimiento-informado" component={InformedConsentReception}/> 
            <Route path="/cuidador-paciente" component={PatientCarer}/>  
            <Redirect from="*" to="/agenda" />
          </Switch>
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

export default Enroller;
