import React, { useState, useEffect } from 'react';
import { Container, Input, Card, CardBody, Table } from 'reactstrap';
import { getList } from 'helpers/ListHelper';
import axios from '../../../helpers/axiosConfig';

const CategoryCapsules = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [capsules, setCapsules] = useState([]);
    const genericId = 1; // ID de la categoría "Para todos"

    useEffect(() => {
        fetchCapsules();
        fetchCategories();
    }, []);

    const fetchCapsules = async () => {
        const res = await getList('list/capsule-categories');
        const groupedCapsules = groupCapsulesByCategory(res);
        setLoading(false);
        setCapsules(groupedCapsules);
    };

    const fetchCategories = async () => {
        const res = await getList('list/pacient-categories');
        setLoading(false);
        setCategories(res);
    };

    const groupCapsulesByCategory = (capsules) => {
        const groupedCapsules = capsules.reduce((acc, curr) => {
            if (!acc[curr.capsule_id]) {
                acc[curr.capsule_id] = {
                    capsule_id: curr.capsule_id,
                    capsule_description: curr.capsule_description,
                    categories: {},
                    capsuleCategoryCapsuleIds: {},
                };
            }

            if (curr.category_description) {
                acc[curr.capsule_id].categories[curr.category_description] = true;
                acc[curr.capsule_id].capsuleCategoryCapsuleIds[curr.category_description] = curr.capsule_category_capsule_id;
            }
            return acc;
        }, {});

        return Object.values(groupedCapsules);
    };

    const handleCheckboxChange = async (e, capsule_id, capsule_category_id, capsule_category_capsule_id) => {
        const isChecked = e.target.checked;
        const isGeneric = capsule_category_id === genericId;

        if (isChecked) {
            // Agregar registro
            // console.log('agregar id capsula: ', capsule_id)
            // console.log('agregar catego: ', capsule_category_id)
            await axios.post('/capsule/addCapsuleCategory', { capsule_id, capsule_category_id });
        } else {
            // Eliminar registro
            // console.log('id borrar: ', capsule_category_capsule_id)
            await axios.delete('/capsule/deleteCapsuleCategory', { data: { capsule_category_capsule_id } });
        }

        // Actualizar el estado de los checkboxes
        const updatedCapsules = capsules.map((c) => {
            if (c.capsule_id === capsule_id) {
                let updatedCategories;
                if (isGeneric && isChecked) {
                    // Si se está marcando "Generico", desmarcar todas las otras categorías para esta cápsula específica
                    updatedCategories = { "Para todos": true };
                } else {
                    updatedCategories = {
                        ...c.categories,
                        [categories.find((category) => category.id === capsule_category_id).description]: isChecked,
                    };
                    // Si la categoría "Generico" está marcada y se está marcando otra categoría, desmarcar "Generico"
                    if (c.categories["Para todos"] && !isGeneric) {
                        updatedCategories["Para todos"] = false;
                    }
                }
                return { ...c, categories: updatedCategories };
            }
            return c;
        });

        setCapsules(updatedCapsules);
    };

    const headerStyle = {
        maxWidth: '130px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };

    return (
        <Container className="mt-3">
            <Card>
                <CardBody>
                    <h1>Asignación de categorías de cápsulas</h1>
                    <Input
                        type="text"
                        placeholder="Buscar..."
                        autoComplete="off"
                        name="look_for"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <br />
                    {loading
                        ? <div className="w-100 d-flex justify-content-center">
                            <div className="loader"></div>
                        </div>
                        : <>
                            <Table className="align-items-center table-responsive" responsive>
                                <thead className="thead-light">
                                    <tr className="text-center">
                                        <th scope="col">Cápsula</th>
                                        {categories.map((category) => (
                                            <th scope="col" key={category.id} style={headerStyle}>{category.description}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {capsules
                                        .filter((e) => {
                                            if (searchTerm === "") {
                                                return e;
                                            } else if (
                                                e.capsule_description.toLowerCase().includes(searchTerm.toLowerCase())
                                            ) {
                                                return e;
                                            }
                                        })
                                        .map((e) => (
                                            <tr className="text-center" key={e.capsule_id}>
                                                <td>{e.capsule_description}</td>
                                                {categories.map((category) => (
                                                    <td key={category.id}>
                                                        <Input
                                                            type="checkbox"
                                                            disabled={category.id !== genericId && e.categories["Para todos"]}
                                                            checked={e.categories[category.description] || false}
                                                            onChange={(event) =>
                                                                handleCheckboxChange(
                                                                    event,
                                                                    e.capsule_id,
                                                                    category.id,
                                                                    e.capsuleCategoryCapsuleIds[category.description]
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                </tbody>
                            </Table>
                        </>
                    }
                </CardBody>
            </Card>
        </Container>
    );
};

export default CategoryCapsules;
