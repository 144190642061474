import React, { useState } from 'react'
import {
  Button, Col, Container, Form, FormGroup, Input, Row, InputGroup,
  InputGroupText, InputGroupAddon, Card, CardBody, Label, Progress
} from 'reactstrap'
import Axios from '../../../helpers/axiosConfig'
import { Redirect } from 'react-router-dom';
import ForceIntroductionModal from '../components/ModalMuscleStrength';

const AnamnesisMedicoGeriatra = ({ location }) => {
  const [anamId, setAnamId] = useState(0)
  const [datos, setDatos] = useState({
    velocidadMarcha: 0,
    peso: 0,
    altura: 0,
    calf_circumference: 0,
    imc: 0,
    temperatura: 0,
    frecCardiaca: 0,
    saturacionO2: 0,
    fuerzaMuscular: 0,
    glicemia: 0,
    extraInfo: "",
    weightloss: 0,
    fatigue: 0,
    physical_activity: 0,
    has_fall: 0,
    fall: "",
    has_incontinence: 0,
    incontinence: "",
    has_sleep_disorders: 0,
    sleep_disorders: "",
    comment: "",
    folsteinTest: "",
    pfeifferTest: "",
    yesavageTest: "",
    helper: "",
    second_muscular_strength: 0,
    third_muscular_strength: 0,
    is_medicated: "", 
    medication_number: 0, 
    medicine_name: "",
    sistolic: "",
    diastolic: "",
    second_sistolic: "",
    second_diastolic: ""

  });
  const handleInputChange = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value
    })
  }

  const options = [
    "Si",
    "No puede mostrarlos, pero los indicará verbalmente.",
    "No puede mostrarlos y no recuerda ninguno.",
    "No está tomando o usando medicamentos.",
  ];

  const handleInputCheckedChange = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.checked
    });
  };

  const setStatusPerson = async (idPersona) => {
    const res = await Axios.put('person/set-status-person/',
      {
        status_id: 6,
        personId: idPersona
      }
    )

    if (!res.data.error) {
      console.log('Status actualizado');
    };

  };

  const [next, setNext] = useState(false)

  // const calcIMC = () => {
  //   let w = Number(datos.peso);
  //   let hSq = Number(datos.altura) ** 2;
  //   return w / hSq;
  // }

  const addAMG = async e => {

    e.preventDefault();

    const res = await Axios.post("geriatrical-medical-anamnesis", {
      personId: location.state.id,
      walkingSpeed: Number(datos.velocidadMarcha),
      weight: Number(datos.peso),
      height: Number(datos.altura),
      calf_circumference: Number(datos.calf_circumference),
      //   bmi: calcIMC(),
      bmi: 0,
      temperature: Number(datos.temperatura),
      heartRate: Number(datos.frecCardiaca),
      oxygenSaturation: Number(datos.saturacionO2),
      muscularStrength: datos.fuerzaMuscular,
      second_muscular_strength: datos.second_muscular_strength, 
      third_muscular_strength: datos.third_muscular_strength,
      glycemia: Number(datos.glicemia),
      extraInfo: datos.extraInfo,
      has_incontinence: datos.has_incontinence,
      incontinence: datos.incontinence,
      has_sleep_disorders: datos.has_sleep_disorders,
      sleep_disorders: datos.sleep_disorders,
      comment: datos.comment,
      is_medicated: datos.is_medicated, 
      medication_number: datos.medication_number, 
      medicine_name: datos.medicine_name,
      bloodPressure: datos.sistolic + "/" + datos.diastolic,
      second_bloodPressure: datos.second_sistolic + "/" + datos.second_diastolic
    });
    if (res.data.result) {

      setAnamId(res.data.result.insertId)
      setStatusPerson(location.state.id);
      setNext(true)
    };
  }

  const reqTag = <span style={{ color: "#DC3545" }}>*</span>

  return (
    <Container>
      <Card>
        <CardBody>
          <h3>{location.state.run}, {location.state.name} {location.state.apellido}</h3>
          <h1>
            Anamnesis Médico - Geriátrica
          </h1>
          <h5 className="heading-small" >Parametros Fisicos</h5>
          <Form role="form" onSubmit={addAMG}>
            <FormGroup className="row">
              <Col>
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Velocidad de marcha
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    min={0}
                    max={3}
                    step="0.1"
                    name="velocidadMarcha"
                    onChange={(e) => { handleInputChange(e) }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>m/s</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col>
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Peso
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    min={0}
                    max={200}
                    step="0.1"
                    name="peso"
                    onChange={(e) => { handleInputChange(e) }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>kg</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col>
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Altura
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    min={0.5}
                    max={3}
                    step="0.01"
                    name="altura"
                    onChange={(e) => { handleInputChange(e) }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>m</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col>
                <Label className="form-control-label">Circunferencia de Pantorrilla</Label>
                <InputGroup>
                    <Input
                      type="number" 
                      step="0.01"
                      min={0}
                      name="calf_circumference"
                      onChange={e => handleInputChange(e)}
                    />
                    <InputGroupAddon addonType="append"><InputGroupText>cm</InputGroupText></InputGroupAddon>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Col md="4">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  1ra Presión Arterial (Sistólica / Diastólica)
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    min={80}
                    max={250}
                    step="1"
                    name="sistolic"
                    onChange={(e) => { handleInputChange(e) }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>/</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    min={50}
                    max={200}
                    step="1"
                    name="diastolic"
                    onChange={(e) => { handleInputChange(e) }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>mmHg</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>

              <Col md="4">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  2da Presión Arterial (Sistólica / Diastólica)
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    min={80}
                    max={250}
                    step="1"
                    name="second_sistolic"
                    onChange={(e) => { handleInputChange(e) }}
                  //required
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>/</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="number"
                    min={50}
                    max={200}
                    step="1"
                    name="second_diastolic"
                    onChange={(e) => { handleInputChange(e) }}
                  //required
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>mmHg</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>

              <Col md="4">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Temperatura
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    min={15}
                    max={50}
                    step="1"
                    name="temperatura"
                    onChange={(e) => { handleInputChange(e) }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>ºC</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>

            </FormGroup>

            <FormGroup className="row">
              <Col md="4">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Frecuencia Cardíaca
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    min={40}
                    max={220}
                    step="1"
                    name="frecCardiaca"
                    onChange={(e) => { handleInputChange(e) }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>lat/m</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md="4">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Saturación de Oxígeno
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    min={60}
                    max={100}
                    step="1"
                    name="saturacionO2"
                    onChange={(e) => { handleInputChange(e) }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>%SpO<sub>2</sub></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup  className="row">
                <Col>
                    <Label className="form-control-label">1° Fuerza Muscular  (<ForceIntroductionModal/>)</Label>
                    <InputGroup>
                        <Input
                            name="fuerzaMuscular"
                            onChange={e => handleInputChange(e)}
                            type="number" step="0.1" autoComplete="off" min={0} max={100}
                        />
                        <InputGroupAddon addonType="append"><InputGroupText>kg</InputGroupText></InputGroupAddon>
                    </InputGroup>
                </Col>
                <Col>
                    <Label className="form-control-label">2° Fuerza Muscular  (<ForceIntroductionModal/>)</Label>
                    <InputGroup>
                        <Input
                            name="second_muscular_strength"
                            onChange={e => handleInputChange(e)}
                            type="number" step="0.1" autoComplete="off" min={0} max={100}
                            />
                        <InputGroupAddon addonType="append"><InputGroupText>kg</InputGroupText></InputGroupAddon>
                    </InputGroup>
                </Col>
                <Col>
                    <Label className="form-control-label">3° Fuerza Muscular (<ForceIntroductionModal/>)</Label>
                    <InputGroup>
                        <Input
                            name="third_muscular_strength"
                            onChange={e => handleInputChange(e)}
                            type="number" step="0.1" autoComplete="off" min={0} max={100}
                        />
                        <InputGroupAddon addonType="append"><InputGroupText>kg</InputGroupText></InputGroupAddon>
                    </InputGroup>
                 </Col>
            </FormGroup>

            <FormGroup className="row">
              <Col md="4">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Glicemia
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    min={50}
                    max={700}
                    name="glicemia"
                    onChange={(e) => { handleInputChange(e) }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>mg/dL</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </FormGroup>

            <h5 className="heading-small">Inventario de Medicamentos</h5>
            <FormGroup className="row">
                <Col md="6">
                    <Label className="form-control-label mb-3">
                        ¿Podría mostrarme los medicamentos y remedios que actualmente está usando?
                    </Label>
                    <Input
                        type="select"
                        name="is_medicated"
                        onChange={e => handleInputChange(e)}
                    >
                        <option hidden value="">Seleccione...</option>
                        {options.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </Input>
                </Col>
                <Col md="6">
                <Label className="form-control-label mt-1">Número total de compuestos activos</Label>
                <br/>
                <br/>
                    <Input
                        type="number"
                        autoComplete="off"
                        maxLength="250"
                        name="medication_number"
                        max={20}
                        min={0}
                        onChange={e => handleInputChange(e)}
                    />
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Col>
                    <Label className="form-control-label">Nombres de los medicamentos que está actualmente tomando</Label>
                    <Input
                        type="textarea" 
                        autoComplete="off" 
                        maxLength="250"
                        name="medicine_name"
                        onChange={e => handleInputChange(e)}
                    />
                </Col>
            </FormGroup>
            
            <FormGroup className="row">
              <Col md="8">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Información Adicional
                </Label>
                <InputGroup>
                  <Input
                    type="textarea"
                    name="extraInfo"
                    onChange={(e) => { handleInputChange(e) }}
                  />
                </InputGroup>
              </Col>
            </FormGroup>

            <h5 className="heading-small">Síndromes geriátricos</h5>
            <FormGroup className="row">
              <Col md="3">
                <Label
                  className="form-control-label"
                >
                  Antecedentes de Incontinencia urinaria/fecal
                </Label>
              </Col>
              <Col md="2">
                <label className="custom-toggle custom-toggle-warning mr-1">
                  <Input type="checkbox" name="has_incontinence" onChange={(e) => { handleInputCheckedChange(e) }} />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Sí"
                  />
                </label>

              </Col>
              <Col md="8">
                {datos.has_incontinence ?
                  <>
                    <Input
                      placeholder=""
                      id="example-text-input"
                      name="incontinence"
                      type="textarea"
                      maxLength="250"
                      onChange={(e) => { handleInputChange(e) }}

                    />
                  </> : null
                }
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Col md="3">
                <Label
                  className="form-control-label"
                >
                  Antecedentes de trastornos del sueño
                </Label>
              </Col>
              <Col md="2">
                <label className="custom-toggle custom-toggle-warning mr-1">
                  <Input type="checkbox" name="has_sleep_disorders" onChange={(e) => { handleInputCheckedChange(e) }} />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Sí"
                  />
                </label>

              </Col>
              <Col md="8">
                {datos.has_sleep_disorders ?
                  <>
                    <Input
                      placeholder=""
                      id="example-text-input"
                      name="sleep_disorders"
                      type="textarea"
                      maxLength="250"
                      onChange={(e) => { handleInputChange(e) }}

                    />
                  </> : null
                }
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Col md="8">
                <Label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Comentarios
                </Label>
                <InputGroup>
                  <Input
                    type="textarea"
                    name="comment"
                    onChange={(e) => { handleInputChange(e) }}
                  />
                </InputGroup>
              </Col>
            </FormGroup>

            <FormGroup className="row">
              <Col md="10">

              </Col>
              <Col md="2">
                <Button type="submit" color="primary">Guardar</Button>
              </Col>
              {next && <Redirect to={{
                pathname: "/evaluaciones",
                state: { id: location.state.id, run: location.state.run, name: location.state.name, apellido: location.state.apellido, anamId: anamId }
              }} />}
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}

export default AnamnesisMedicoGeriatra;