import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Col } from 'reactstrap';

const CreateControlModal = ({
    modal,
    toggleModal,
    handleSubmit,
    professionals,
    assignedProfessional,
    setAssignedProfessional,
    setControlDate,
    setAssessment,
    setDescription,
    setTreatmentPerformed,
    setSecondaryDressingUsed,
    setPassiveDressingUsed
}) => {
    return (
        <Modal isOpen={modal} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>Nuevo Control MR</ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit}>
                    <FormGroup className="row">
                        <Col md="6">
                            <Label className="form-control-label">Profesional encargado</Label>
                            <Input
                                type="select"
                                name="assignedProfessional"
                                value={assignedProfessional}
                                onChange={(e) => setAssignedProfessional(e.target.value)}
                                disabled={professionals.length === 0}
                            >
                                <option value="" disabled>Seleccione a un profesional...</option>
                                {professionals.map((professional) => (
                                    <option key={professional.professional_id} value={professional.professional_id}>
                                        {professional.professional_fullname}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                        <Col md="6">
                            <Label className="form-control-label">Fecha</Label>
                            <Input
                                type="date"
                                name="controlDate"
                                onChange={(e) => {setControlDate(e.target.value)}}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Col md="12">
                            <Label className="form-control-label">Valoración</Label>
                            <Input
                                autoComplete="off" maxLength="250"
                                type="textarea"
                                name="assessment"
                                onChange={(e) => {setAssessment(e.target.value)}}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Col md="12">
                            <Label className="form-control-label">Descripción</Label>
                            <Input
                                autoComplete="off" maxLength="250"
                                type="textarea"
                                name="description"
                                onChange={(e) => {setDescription(e.target.value)}}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Col md="12">
                            <Label className="form-control-label">Tratamiento realizado</Label>
                            <Input
                                autoComplete="off" maxLength="250"
                                type="textarea"
                                name="treatmentPerformed"
                                onChange={(e) => {setTreatmentPerformed(e.target.value)}}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Col md="12">
                            <Label className="form-control-label">Apósito Secundario Utilizado</Label>
                            <Input
                                autoComplete="off" maxLength="250"
                                type="textarea"
                                name="secondaryDressingUsed"
                                onChange={(e) => {setSecondaryDressingUsed(e.target.value)}}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Col md="12">
                            <Label className="form-control-label">Apósito Pasivo Utilizado</Label>
                            <Input
                                autoComplete="off" maxLength="250"
                                type="textarea"
                                name="passiveDressingUsed"
                                onChange={(e) => {setPassiveDressingUsed(e.target.value)}}
                            />
                        </Col>
                    </FormGroup>
                    <ModalFooter>
                    <Button color="primary" type="submit">Guardar</Button>{' '}
                    <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
                    </ModalFooter>
                </form>            
            </ModalBody>
        </Modal>
    );
};

export default CreateControlModal;
