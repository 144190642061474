import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Container, FormGroup, Input, Card, CardBody, Label, Table, Modal, ModalHeader, ModalBody, Form } from 'reactstrap';
import { getList } from 'helpers/ListHelper';
import SimpleModal from "../components/SimpleModal";
import axios from '../../../helpers/axiosConfig';
import { Link } from 'react-router-dom';

const ConfCapsules = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    useEffect(() => {
        fetchCapsules();
        fetchCategories();
    }, []);

    /*CAPSULES##################################################################################*/
    const defaultCapsule = { capsule_id: 0, capsule_description: "", capsule_url: "", categories: {} };
    const [capsules, setCapsules] = useState([]);
    const [capsule, setCapsule] = useState(defaultCapsule);

    const [categories, setCategories] = useState([]);

    const fetchCategories = async () => {
        const res = await getList("list/pacient-categories");
        // console.log('categorias', res)
        setCategories(res);
    };

    const fetchCapsules = async () => {
        const res = await getList("list/capsules");
        const capsulesWithCategoriesAsObject = res.map(capsule => {
            const categoriesObject = {};
            capsule.categories.forEach(categoryId => {
                categoriesObject[categoryId] = true;
            });
            return { ...capsule, categories: categoriesObject };
        });
        setLoading(false);
        setCapsules(capsulesWithCategoriesAsObject);
    };

    const handleInputChangeCapsule = e => {
        if (e.target) {
            if (e.target.name === 'categories') {
                let updatedCategories = { ...capsule.categories };
                let atLeastOneChecked = false;
    
                if (e.target.value === '1') {
                    // Si se selecciona "Para Todos", se deseleccionan los demás.
                    Object.keys(updatedCategories).forEach(key => {
                        if(key !== '1') { 
                          updatedCategories[key] = false;
                        }
                    });
                    updatedCategories['1'] = e.target.checked;
                } else {
                    // Si se selecciona otra categoría, se deselecciona "Para Todos".
                    updatedCategories['1'] = false;
                    updatedCategories[e.target.value] = e.target.checked;
                }
    
                // Verifica si al menos un checkbox está seleccionado.
                Object.values(updatedCategories).forEach(value => {
                    if(value) {
                        atLeastOneChecked = true;
                    }
                });
    
                setIsCheckboxChecked(atLeastOneChecked);
    
                setCapsule(prevState => ({
                    ...prevState,
                    categories: updatedCategories
                }));
            } else {
                setCapsule({
                    ...capsule,
                    [e.target.name]: e.target.value
                });
            }
        }
    };
    


    /*MODAL#####################################################################################*/
    const [displayModalCapsule, setDisplayModalCapsule] = useState(false);
    const showModalCapsule = capsule => {
        setCapsule(capsule);
        setDisplayModalCapsule(!displayModalCapsule);
    };

    /*SIMPLEMODAL###############################################################################*/
    const modalRef = useRef()
    const setModal = (options) => {
        modalRef.current.setModal(options);
    };

    /*CRUD######################################################################################*/
    const sendCapsule = async e => {
        e.preventDefault();
        if(!isCheckboxChecked) {
            setModal({ title: 'Error', text: 'Por favor, selecciona al menos una categoría.', type: 1 });
            return;
        }
        try {
            const res = await axios.put("person/capsule/", capsule);
            if(res.data.result.cod === 0){
                fetchCapsules();
                setDisplayModalCapsule(!displayModalCapsule);
            }else{
                switch (res.data.result[0][0].cod) {
                    case 1:
                        setModal({ title: 'Cápsulas', text: 'La cápsula ' + capsule.capsule_description + ' ya existe.', type: 1 });
                        break;
                    case 2:
                        setModal({ title: 'Cápsulas', text: 'La cápsula ' + capsule.capsule_description + ' ya existe pero esta inactiva', type: 1 });
                        break;
                    default:
                        setModal({ title: 'Error', text: 'Despierte al administrador de sistemas ' + res.data.result[0][0].msg, type: 1 });
                        break;
                };
            }
            
        } catch (e) {
            console.log('Err: ' + e);
        };
    };

    const updateStateCapsule = async (capsule_id) => {
        await axios.put("person/capsule/" + capsule_id);
        setModal({});
        fetchCapsules();
    };

    const reqTag = <span style={{ color: "#DC3545" }}>*</span>

    return (
        <Container className="mt-3">
            <SimpleModal ref={modalRef} />
            <Card>
                <CardBody>
                    <h1>Configuración de cápsulas educativas App</h1>
                    <div align="right">
                        <Button color="primary">
                            <Link
                                style={{ color: "white" }}
                                to={{
                                    pathname: "/categorias-capsulas"
                                }}>Categorías de capsulas
                            </Link>
                        </Button>
                    </div>
                    <br />
                    <Input type="text" placeholder="Buscar..." autoComplete="off" name="look_for" value={searchTerm} onChange={e => setSearchTerm(e.target.value)}></Input>
                    <br />
                    {loading
                        ? <div className="w-100 d-flex justify-content-center">
                            <div className="loader"></div>
                        </div>
                        : <>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr className="text-center">
                                        <th scope="col">Descripcion</th>
                                        <th scope="col">URL</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* eslint-disable-next-line */}
                                    {capsules.filter(e => {
                                        if (searchTerm === "") {
                                            return e;
                                        } else if (e.capsule_description.toLowerCase().includes(searchTerm.toLowerCase())) {
                                            return e;
                                        }
                                    }).map(e => (
                                        <tr className="text-center" key={e.capsule_id}>
                                            <td>{e.capsule_description}</td>
                                            <td><a rel="noopener noreferrer" target="_blank" href={e.capsule_url}>{e.capsule_url}</a></td>
                                            <td>
                                                <Button onClick={() => showModalCapsule(e)} title="Editar" className="mr-2" color="primary" size="sm" outline><i className="far fa-edit" /></Button>
                                            </td>
                                            <td>
                                                <Button onClick={() => setModal({ title: 'Eliminando cápsula', text: 'Desea eliminar cápsula ' + e.capsule_description + '?', type: 2, fx: () => updateStateCapsule(e.capsule_id) })} title="Eliminar" className="mr-2" color="danger" size="sm" outline><i className="far fa-trash-alt" /></Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div className="float-right mb-1 mr-4 mt-3">
                                <Button color="primary" onClick={() => showModalCapsule(defaultCapsule)}>Nueva</Button>
                            </div>
                        </>
                    }
                </CardBody>
            </Card>
            <Modal isOpen={displayModalCapsule}>
                <ModalHeader toggle={() => { setDisplayModalCapsule(!displayModalCapsule) }}>
                    {capsule.capsule_id === 0 ? 'Nueva cápsula' : 'Editando cápsula'}
                </ModalHeader>
                <ModalBody className="pt-1">
                    <Form role="form" onSubmit={e => sendCapsule(e)}>
                        <h5 style={{ color: "#DC3545" }}>* Existen campos obligatorios</h5>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Descripción {reqTag}</Label>
                                <Input
                                    required autoComplete="off" maxLength="130" type="text"
                                    name="capsule_description"
                                    value={capsule.capsule_description}
                                    onChange={e => handleInputChangeCapsule(e)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Link {reqTag}</Label>
                                <Input
                                    required autoComplete="off" maxLength="500" type="text"
                                    name="capsule_url"
                                    value={capsule.capsule_url}
                                    onChange={e => handleInputChangeCapsule(e)}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Categorías {reqTag}</Label>
                                {categories.map(category => (
                                    <FormGroup check key={category.id}>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="categories"
                                                value={category.id}
                                                onChange={e => handleInputChangeCapsule(e)}
                                                checked={capsule.categories[category.id] || false}
                                                disabled={category.id !== 1 && capsule.categories[1]}
                                            />
                                            {category.description}
                                        </Label>
                                    </FormGroup>
                                ))}
                            </Col>
                        </FormGroup>
                        <div className="float-right mb-2 mr-2">
                            <Button type="submit" color="primary">Guardar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </Container>
    );
};

export default ConfCapsules;