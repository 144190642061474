import React, { useState } from "react";

const Tooth = ({id, paths, status, handleTeethStatus}) => {

    //OLD COLORS
    // const colorLegend={
    //     1:'#222526', //ausente 
    //     2:'#F4F2E5', //completo
    //     3:'#E20000', //fracturado
    //     4:'#006666', //resto radicular
    //     5:'#878681' //implante
    // }

    const colorLegend = {
        1: '#f5f0f1', //ausente 
        2: '#F4F2E5', //completo
        3: '#ff8080', //fracturado
        4: '#c4e5c9', //resto radicular
        5: '#878681' //implante
    }



    const [stroke, setStroke] = useState(false)

    const handleClickTooth=(e)=>{
        console.log(e.currentTarget.id)
        handleTeethStatus(e)
        setStroke(!stroke)
    }

    console.log(status)
    return (
        <>
        
        <g
                id={id}
                fill="none"
                stroke={status!==1 &&"#6a6565"}
                strokeLinejoin="miter"
                strokeOpacity="1"
                strokeWidth= "0.265"
                display="inline"
                transform="translate(-35.628 -25.806)"
                onClick={handleClickTooth}
            >  
                {paths.map( (path, index) =>
                    <path
                            key={id+index}
                            id={id}
                            strokeLinecap="butt"
                            d={path.d}
                            fill={colorLegend[status]}
                        >
                    </path>
                )
                }
        </g>
        </>
    )
}

export default Tooth