import React, { useState } from 'react'
import {
    Button, Col, Container, Form, FormGroup, Input, Row, InputGroup,
    InputGroupText, InputGroupAddon, Card, CardBody, Label, ListGroup, lisgro
} from 'reactstrap'

import Axios from '../../../helpers/axiosConfig'
import { Redirect } from 'react-router-dom';

const Background = ({location}) => {

    const [alcohol, setAlcohol] = useState(false)
    const [lastTime, setLastTime] = useState("")
    const [drugs, setDrugs] = useState(false)
    const [smoke, setSmoke] = useState(false)
    const [smokeCuantity, setSmokeCuantity]= useState(0)
    const [badHabits, setBadHabits] = useState("")   
    const [bruxismo, setBruxismo] = useState(false)
    const [dryMouth, setDryMouth] = useState(false)
    const [eat10, setEat10] = useState(0)
    const [has_eat, setHasEat] = useState(false)
    const [normal_pressure_1, setNormalPressure1] = useState(0)
    const [normal_pressure_2, setNormalPressure2] = useState(0)
    const [normal_pressure_3, setNormalPressure3] = useState(0)
    const [forced_pressure, setForcedPressure] = useState(0)
    const [sugary, setSugary] = useState(false)
    const [sugaryCuantity, setSugaryCuantity] = useState("")
    const [starchy, setStarchy] = useState(false)
    const [starchyCuantity, setStarchyCuantity] = useState("")
    const [hydration, setHydration] = useState(false)

    const [toothBrushingFreq, setToothBrushingFreq] = useState("")
    const [toothbrush, setToothbrush] = useState(0)
    const [fluoridatedToothpaste, setFluoridatedToothpaste] = useState(0)
    const [floss, setFloss] = useState(0)
    const [mouthwash, setMouthwash] = useState(0)
    const [interdentalBrush, setInterdentalBrush] = useState(0)
    const [electricToothbrush, setElectricToothbrush] = useState(0)
    const [otherImplements, setOtherImplements] = useState("")




    const [anam_id, setAnamId] = useState(0)

 
    const [next, setNext]=useState(false)

    const addSmoke= () =>{
        setSmoke(!smoke)
        setSmokeCuantity(0)

    }

    const addBackground = async (e) => {
        e.preventDefault()
        const res = await Axios.post("geriatrical-odonto-anamnesis/background",
            {
                personId : location.state.id,
                alcohol: alcohol,
                lastTime: lastTime,
                drugs:drugs,
                smoke:smoke,
                smokeCuantity:smokeCuantity,
                badHabits:badHabits,
                bruxismo:bruxismo,
                dry_mouth: dryMouth,
                eat_10: eat10,
                has_eat: has_eat,
                normal_pressure_1: normal_pressure_1,
                normal_pressure_2, normal_pressure_2,
                normal_pressure_3: normal_pressure_3,
                forced_pressure: forced_pressure, 
                sugary_foods:sugary, 
                sugary_foods_cuantity:sugaryCuantity, 
                starchy_foods:starchy, 
                starchy_foods_cuantity:starchyCuantity, 
                hydration:hydration,
                tooth_brushing_freq: toothBrushingFreq,
			    toothbrush: toothbrush,
		        fluoridated_toothpaste: fluoridatedToothpaste,
			    floss: floss,
		        mouthwash: mouthwash ,
		        interdental_brush: interdentalBrush,
			    electric_toothbrush: electricToothbrush,
		        other_implements: otherImplements,
            }
        )
        if (!res.data.error)
            setAnamId(res.data.result.insertId); 
            setNext(true);  
    }


    return (
        <Container>
            <Card>
                <CardBody>
                    <h3>{location.state.run}, {location.state.name} {location.state.apellido}</h3>
                    <h1>
                        Anamnesis Odonto-geriátrica
                    </h1>
                    <h5 className="heading-small" >Antecedentes</h5>
                    
                    <Form  onSubmit={addBackground} role="form">
                        <FormGroup className="row">
                            <Col>
                            <Label
                                className="form-control-label"
                            >
                                ¿Cuando fue la última vez que vine al dentista?
                            </Label>
                            
                                <Input
                                    defaultValue=""
                                    id="example-text-input"
                                    type="textarea"
                                    onChange={(e) => { setLastTime(e.target.value) }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="2">
                                ¿Bebe alcohol?
                            </Col>
                            <Col md="10">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { setAlcohol(!alcohol) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="2">
                            ¿Consume drogas?
                            </Col>
                            <Col md="10">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { setDrugs(!drugs) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="2">
                                ¿Fuma?
                            </Col>
                            <Col md="2">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { addSmoke() }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                            {smoke &&
                            <>
                            <Col md="2">
                            Cantidad
                            </Col> 
                            <Col md="2">
                            <label className="">
                                <Input type="number" onChange={(e) => { setSmokeCuantity(e.target.value) }} />
                            </label>
                            </Col>
                            </>
                            
                            }
                        </FormGroup>
                        <FormGroup className="row">
                            <Col >
                            <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                                
                            >
                                Observaciones
                            </Label>
                            
                                <Input
                                    defaultValue=""
                                    id="example-text-input"
                                    type="textarea"
                                    maxLength="250"
                                    onChange={(e) => { setBadHabits(e.target.value) }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="2">
                                ¿Bruxismo?
                            </Col>
                            <Col md="10">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { setBruxismo(!bruxismo) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="2">
                                ¿Siente su boca seca?
                            </Col>
                            <Col md="10">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { setDryMouth(!dryMouth) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <h5 className="heading-small">Trastornos de Deglución</h5>
                        <FormGroup className="row">
                            <Col>
                                <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect3"
                                >
                                    Formulario EAT-10 (
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScs-W8bdSvzndQk2Lhg4bEYYm-fJp4i9JeIH6Y1x7AUjCLhKg/viewform"
                                    target="_blank">
                                        ver</a>) 
                                </Label>
                                <Input
                                    placeholder=""
                                    id="example-text-input"
                                    min="0" max="40" 
                                    name="eat_10"
                                    type="number"
                                    onChange={(e) => {setEat10(e.target.value)}}
                                    
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                                    <Col className= "d-flex">
                                        <Label className="form-control-label">¿Posible trastorno de deglución?  (puntaje  {'>'} 3 en cuestionario EAT10)</Label>  
                                        <label className="custom-toggle custom-toggle-warning ml-3">
                                            <Input
                                                type="checkbox"                                                
                                                onChange={(e) => {setHasEat(!has_eat)}}
                                            />
                                            <span className="custom-toggle-slider rounded-circle" data-label-off="No" data-label-on="Sí"/>
                                        </label>                                              
                                    </Col>
                                </FormGroup>                           
                                {has_eat?
                                    <>
                                        <FormGroup className="row">
                                            <Col >
                                                <Label className="form-control-label" > Presión en deglución normal {'('}realizar 3 mediciones{')'}</Label>                                    
                                            </Col>
                                        </FormGroup>                               
                                        <FormGroup className="row">                                    
                                                <Col md="3">
                                                    <InputGroup>
                                                        <Input          
                                                            min="-300" max="300"                                                 
                                                            type="number"                                                            
                                                            onChange={(e) => {setNormalPressure1(e.target.value)}}                                                
                                                        /> 
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>mbar</InputGroupText>    
                                                        </InputGroupAddon>
                                                    </InputGroup>                                           
                                                </Col>
                                                <Col md="3">
                                                    <InputGroup>
                                                        <Input
                                                            min="-300" max="300"
                                                            type="number"                                                            
                                                            onChange={(e) => {
                                                                setNormalPressure2(e.target.value)
                                                            }}                                                
                                                        />                         
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>mbar</InputGroupText>    
                                                        </InputGroupAddon>
                                                    </InputGroup>                                                                    
                                                </Col>
                                                <Col md="3"> 
                                                    <InputGroup>
                                                        <Input                                                           
                                                            min="-300" max="300"                                                             
                                                            type="number"                                                            
                                                            onChange={(e) => {
                                                                setNormalPressure3(e.target.value)
                                                            }}                                                
                                                        />                                            
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>mbar</InputGroupText>    
                                                        </InputGroupAddon>
                                                    </InputGroup>                                                                                   
                                                </Col>                                    
                                        </FormGroup>
                                        <FormGroup className="row">
                                            <Col>
                                                <Label className="form-control-label" >Presión en deglución forzada</Label>
                                                <InputGroup>
                                                    <Input                                                           
                                                        min="-300" max="300"                                                             
                                                        type="number"                                                            
                                                        onChange={(e) => {
                                                            setForcedPressure(e.target.value)
                                                        }}                                                
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText>mbar</InputGroupText>    
                                                    </InputGroupAddon>
                                                </InputGroup> 
                                            </Col>
                                        </FormGroup>
                                    </>:null
                                }
                            
                        <h5 className="heading-small">Alimentación cariogénica</h5>
                        <FormGroup className="row">
                            <Col md="6">
                                    <Label
                                        className="form-control-label"
                                    >
                                        ¿Consume alimentos y/o bebidas azucaradas (como golosinas, galletitas, pasteles, tortas dulces, gaseosa o jugos azucarados, café, té, leche con azúcar) entre las comidas?
                                    </Label>
                            </Col> 
                            <Col md="2">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input 
                                        type="checkbox" 
                                        name="sugary_foods"
                                        onChange={(e) => { setSugary(!sugary) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                                    {sugary &&
                                        <>
                                        <Col>
                                            <Label className="form-control-label">Describa cuántas veces al día y en qué momento consume estos alimentos</Label>
                                            <Input
                                                    placeholder=""
                                                    id="example-text-input"
                                                    name="sugary_foods_cuantity"
                                                    type="textarea"
                                                    maxLength="250"
                                                    onChange={(e) => { setSugaryCuantity(e.target.value) }}   
                                            />
                                        </Col>
                                        </>
                                    }
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="3">
                                    <Label
                                        className="form-control-label"
                                    >
                                        ¿Consume pan, arroz, tallarines, masas, etc.?
                                    </Label>
                            </Col> 
                            <Col md="2">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" name="starchy_foods" onChange={(e) => { setStarchy(!starchy) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            {starchy&&
                            <>
                            <Col>
                                <Label className="form-control-label">Describa cuántas veces al día y en qué momento consume estos alimentos</Label>
                                <Input
                                        placeholder=""
                                        id="example-text-input"
                                        name="starchy_foods_cuantity"
                                        type="textarea"
                                        maxLength="250"
                                        onChange={(e) => { setStarchyCuantity(e.target.value) }}
                                        
                                />
                            </Col>
                            </>
                            }
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="3">
                                    <Label
                                        className="form-control-label"
                                    >
                                        ¿Consume 2 Lt de agua al día? 
                                    </Label>
                            </Col> 
                            <Col md="2">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" name="hydration"  onChange={(e) => { setHydration(!hydration) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>

                        <h5 className="heading-small">Higiene Oral</h5>
                        <FormGroup className="row">
                            <Col >
                            <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                                
                            >
                                Describa cuántas veces al día cepilla sus dientes
                            </Label>
                            
                                <Input
                                    defaultValue=""
                                    id="example-text-input"
                                    type="textarea"
                                    maxLength="250"
                                    onChange={(e) => {setToothBrushingFreq(e.target.value) }}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup className="row">
                            <Col md="3">
                                    <Label
                                        className="form-control-label"
                                    >
                                        Cepillo 
                                    </Label>
                            </Col> 
                            <Col md="2">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { setToothbrush(!toothbrush) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="3">
                                    <Label
                                        className="form-control-label"
                                    >
                                        Pasta dental fluorada 
                                    </Label>
                            </Col> 
                            <Col md="2">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { setFluoridatedToothpaste(!fluoridatedToothpaste) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="3">
                                    <Label
                                        className="form-control-label"
                                    >
                                        Seda o hilo dental 
                                    </Label>
                            </Col> 
                            <Col md="2">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { setFloss(!floss) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="3">
                                    <Label
                                        className="form-control-label"
                                    >
                                        Enjuague bucal 
                                    </Label>
                            </Col> 
                            <Col md="2">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { setMouthwash(!mouthwash) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="3">
                                    <Label
                                        className="form-control-label"
                                    >
                                        Cepillos interproximales
                                    </Label>
                            </Col> 
                            <Col md="2">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { setInterdentalBrush(!interdentalBrush) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col md="3">
                                    <Label
                                        className="form-control-label"
                                    >
                                        Cepillo eléctrico
                                    </Label>
                            </Col> 
                            <Col md="2">
                                <label className="custom-toggle custom-toggle-warning mr-1">
                                    <Input type="checkbox" onChange={(e) => { setElectricToothbrush(!electricToothbrush) }} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Sí"
                                    />
                                </label>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col >
                            <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                                
                            >
                                Otros
                            </Label>
                            
                                <Input
                                    defaultValue=""
                                    id="example-text-input"
                                    type="textarea"
                                    maxLength="250"
                                    onChange={(e) => { setOtherImplements(e.target.value) }}
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup className="row">
                            <Col md="10">
                            </Col>
                            <Col md="2">
                        
                            <Button type="submit" color="primary">Registrar</Button>
                            
                            </Col>
                        </FormGroup>
                    </Form>
                    { next && <Redirect to={{
                    pathname: "/examen-muscular",
                    state: { id: location.state.id, run: location.state.run, name: location.state.name, apellido : location.state.apellido, anamId: anam_id} 
                    }} /> } 
                </CardBody>
            </Card>
        </Container>
    )
}

export default Background
