import React from "react";

const Legend = () => {

    const styleText = {
        fill: "#6a6565",
        fontWeight: "bold",
        fontSize: "4.7",
        letterSpacing: "0",
        wordSpacing: "0"
    }


    return (
        <g fillOpacity="1" transform="translate(-34.5 -23)">
            <text
                xmlSpace="preserve"
                x="35.006"
                y="104.605"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                1.8
            </text>
            <text
                xmlSpace="preserve"
                x="38.168"
                y="88.19"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                1.7
            </text>
            <text
                xmlSpace="preserve"
                x="42.037"
                y="74.415"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                1.6
            </text>
            <text
                xmlSpace="preserve"
                x="47.549"
                y="62.011"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                1.5
            </text>
            <text
                xmlSpace="preserve"
                x="53.338"
                y="51.812"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                1.4
            </text>
            <text
                xmlSpace="preserve"
                x="64.088"
                y="39.409"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                1.3
            </text>
            <text
                xmlSpace="preserve"
                x="77.318"
                y="31.14"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                1.2
            </text>
            <text
                xmlSpace="preserve"
                x="92.478"
                y="27"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                1.1
            </text>
            <text
                xmlSpace="preserve"
                x="105.433"
                y="27"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                2.1
            </text>
            <text
                xmlSpace="preserve"
                x="121.42"
                y="31.691"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                2.2
            </text>
            <text
                xmlSpace="preserve"
                x="135.753"
                y="41.338"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                2.3
            </text>
            <text
                xmlSpace="preserve"
                x="146.415"
                y="54.613"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                2.4
            </text>
            <text
                xmlSpace="preserve"
                x="151.74"
                y="66.145"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                2.5
            </text>
            <text
                xmlSpace="preserve"
                x="158.048"
                y="78.549"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                2.6
            </text>
            <text
                xmlSpace="preserve"
                x="161.387"
                y="92.055"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                2.7
            </text>
            <text
                xmlSpace="preserve"
                x="161.663"
                y="105.01"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                2.8
            </text>
            <text
                xmlSpace="preserve"
                x="38.995"
                y="150.991"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                4.8
            </text>
            <text
                xmlSpace="preserve"
                x="42.027"
                y="166.955"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                4.7
            </text>
            <text
                xmlSpace="preserve"
                x="45.886"
                y="181.631"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                4.6
            </text>
            <text
                xmlSpace="preserve"
                x="51.674"
                y="194.31"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                4.5
            </text>
            <text
                xmlSpace="preserve"
                x="58.565"
                y="205.335"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                4.4
            </text>
            <text
                xmlSpace="preserve"
                x="69.106"
                y="215.258"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                4.3
            </text>
            <text
                xmlSpace="preserve"
                x="81.994"
                y="221.322"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                4.2
            </text>
            <text
                xmlSpace="preserve"
                x="93.571"
                y="222.932"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                4.1
            </text>
            <text
                xmlSpace="preserve"
                x="103.77"
                y="222.954"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                3.1
            </text>
            <text
                xmlSpace="preserve"
                x="114.483"
                y="221.2"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                3.2
            </text>
            <text
                xmlSpace="preserve"
                x="126.85"
                y="215.239"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                3.3
            </text>
            <text
                xmlSpace="preserve"
                x="138.512"
                y="208.015"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                3.4
            </text>
            <text
                xmlSpace="preserve"
                x="145.773"
                y="196.569"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                3.5
            </text>
            <text
                xmlSpace="preserve"
                x="153.114"
                y="180.943"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                3.6
            </text>
            <text
                xmlSpace="preserve"
                x="157.482"
                y="167.24"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                3.7
            </text>
            <text
                xmlSpace="preserve"
                x="159.791"
                y="151.464"
                fill={styleText.fill}
                fontSize={styleText.fontSize}
                fontWeight={styleText.fontWeight}
                letterSpacing={styleText.letterSpacing}
                wordSpacing={styleText.wordSpacing}
                style={{userSelect: "none"}}
            >
                3.8
            </text>
        </g>
    );
}

export default Legend;
