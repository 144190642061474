import React, { useState, useEffect } from 'react'
import {
    Button, FormGroup, Form, Input, Modal, Label, Col,
    ModalHeader, ModalBody, ModalFooter, CardGroup, Card,
    CardImg, CardBody, CardTitle, CardColumns
} from "reactstrap";

const EditExtraOralForm = props => {
    const [marker, setMarker] = useState(props.currentMarker)
    const [imageTag, setImageTag] = useState(null)
    const [modalImage, setModalImage] = useState(false);
    const toggleImage = () => setModalImage(!modalImage);

    const handleImageModal = () => {
        toggleImage()
    }

    const handleImageTag=(e)=>{
        toggleImage()
        marker.foto = e.target.id
        console.log(e.target.id)
    }

    const handleInputChange = event => {
        const { name, value } = event.target

        setMarker({ ...marker, [name]: value })
    }

    useEffect(() => {
                setMarker(props.currentMarker)
    },[props])

    return (
        <>
            <Modal
                centered={true} 
                isOpen={props.editing}>
                <ModalHeader>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={event => {
                        event.preventDefault()
                        props.updateMarker(marker.id, marker)
                    }} 
                    role="form">
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label" >Inspección</Label>
                                <Input
                                    id="example-text-input1"
                                    type="text"
                                    name="inspection"
                                    defaultValue={marker.inspection}
                                    //pattern="^[ñA-Za-z]*[ñA-Za-z][ñA-Za-z]*$"
                                    //required
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Col>
                                <Label className="form-control-label">Palpación</Label>
                                <Input
                                    id="example-text-input1"
                                    type="text"
                                    name="palpation"
                                    defaultValue={marker.palpation}
                                    //pattern="^[ñA-Za-z]*[ñA-Za-z][ñA-Za-z]*$"
                                    //required
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                        <Col>
                            <Label className="form-control-label">Seleccionar Imagen</Label>
                            
                        </Col>
                        <Col>
                            <Button color="primary"
                                    onClick={() => handleImageModal()}><i className="fas fa-camera"></i>
                            </Button>
                            </Col>
                        </FormGroup>
                        <ModalFooter>
                            <Button type="submit" color="primary" >
                                Guardar
                            </Button>
                            <Button color="secondary"
                                onClick={() => props.setEditing(false)}>Cancelar
                             </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal size='lg' centered={true} isOpen={modalImage} toggle={toggleImage}>
                        <ModalHeader toggle={toggleImage} >Imagenes</ModalHeader>
                        <ModalBody>
                            <CardColumns>
                                {props.image.map((image) => (
                                    <Card key={image.foto_id} >
                                        <CardImg onClick={(e)=> handleImageTag(e) } id={image.foto_id} top width="50%" height="200" src={process.env.REACT_APP_API_URL + `${image.url}` + "/" + `${image.foto_nombre}`} alt={image.foto_nombre} />
                                        {/* <Input
                                            id={image.foto_id}
                                            type="image"
                                            name="foto"
                                            defaultValue={marker.foto}
                                            onChange={handleInputChange}
                                        /> */}
                                        <CardTitle tag="h5">{image.foto_nombre}</CardTitle>
                                    </Card>
                                ))}
                            </CardColumns>
                        </ModalBody>
                        {/* <ModalFooter>
                            <Button color="primary" onClick={() => console.log('boton')}>Agregar seleccionada</Button>{' '}
                            <Button color="secundary" onClick={toggleImage}>Cancelar</Button>
                        </ModalFooter> */}
            </Modal>

        </>
    )
}
export default EditExtraOralForm